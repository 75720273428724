class Snackbar {
  constructor() {
    this.showBool = false
    this.text = ''
    this.color = null
    this.timeout = 1000
  }

  _show() {
    this.timeout = 1000
    this.showBool = true
  }

  success(text) {
    this.color = 'success'
    this.text = text
    this._show()
  }

  error(text) {
    this.color = 'error'
    this.text = text
    this._show()
  }

  close() {
    this.showBool = false
  }
}

export default new Snackbar()
