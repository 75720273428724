import axios from 'axios'

export default {

  namespaced: true,

  state: {
    user: JSON.parse(localStorage.getItem('user')) || {},
    token: localStorage.getItem('token') || null,
    userStatus: '',
  },

  getters: {
    user: state => state.user,
    userStatus: state => state.userStatus,
    check: state => state.token !== null,
  },

  actions: {

    logout({ commit }) {
      console.log('Logging out...')

      return axios.post('/api/auth/logout').finally(commit('LOGOUT'))
    },
    login({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        console.log('Attempting Login...')
        axios.post('/api/auth/login', credentials).then(res => {
          console.log('Login success')
          commit('SET_TOKEN', res.data.token)
          commit('SET_USER', res.data.user)
          resolve(res)
        }).catch(err => {
          commit('LOGOUT')
          reject(err)
        })
      })
    },
    registerNewUser({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        console.log('Attempting Registration')
        axios.post('/api/auth/register', user).then(res => {
          resolve(res)
        }).catch(err => {
          commit('LOGOUT')
          reject(err)
        })
      })
    },
    fetchUser({ commit }) {
      commit('SET_USER_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get('/api/auth/user').then(res => {
          commit('SET_USER', res.data.data)
          commit('SET_USER_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('LOGOUT')
          commit('SET_USER_STATUS', 'error')
          reject(err)
        })
      })
    },
    updateUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios.patch('/api/auth/user', user).then(res => {
          commit('SET_USER', res.data.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
  mutations: {
    LOGOUT(state) {
      state.user = {}
      state.token = null
      delete axios.defaults.headers.common.Authorization
      localStorage.removeItem('token')
      localStorage.removeItem('user')
    },
    SET_USER(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    SET_USER_STATUS(state, status) {
      state.userStatus = status
    },
    SET_TOKEN(state, token) {
      localStorage.setItem('token', token)
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      state.token = token
    },
  },
}
