// ACHTUNG
import '@/styles/styles.scss'
import Vue from 'vue'
import App from '@/App'
import VueApexCharts from 'vue-apexcharts'
import { VueMaskDirective } from 'v-mask'
import vuetify from './plugins/vuetify'

import router from './router'
import store from './store'

// Plugins
import '@/plugins/axios'
import '@/plugins/filters/filters'
import '@/plugins/mixins/authUser'
import '@/plugins/base'
import '@/plugins/dialog/dialogPlugin'
import '@/plugins/snackbar/snackbarPlugin'
import '@/plugins/dialog/flightDialogPlugin'
import '@/plugins/dialog/pprDialog/pprDialogPlugin'

// Services
import '@/registerServiceWorker'
import '@/service/pusher'

// import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fontsource/montserrat'
import '@mdi/font/css/materialdesignicons.css'
import '@/filters/filters'

Vue.directive('mask', VueMaskDirective)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false
Vue.prototype.$HOMEPAGE_URL = process.env.VUE_APP_HOMEPAGE_URL
Vue.prototype.$API_URL = process.env.VUE_APP_API_ENDPOINT
Vue.prototype.$FBO_IDENT = process.env.VUE_APP_FBO_IDENT

new Vue({
  router,
  store,
  vuetify,
  watch: {
    $route(to, from) {
      this.$store.dispatch('validation/clearErrors')
    },
  },
  render: h => h(App),
}).$mount('#app')
