import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import validation from '@/store/modules/validation'
import flights from '@/store/modules/flights'
import flight from '@/store/modules/flight'
import schedule from '@/store/modules/schedule'
import duty from '@/store/modules/duty'
import privateWings from '@/store/modules/privateWings'
import company from '@/store/modules/company'
import users from '@/store/modules/users'
import companies from '@/store/modules/companies'
import user from '@/store/modules/user'
import occupation from '@/store/modules/occupation'
import files from '@/store/modules/files'
import file from '@/store/modules/file'
import persons from '@/store/modules/persons'
import person from '@/store/modules/person'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    persons,
    person,
    auth,
    validation,
    flights,
    schedule,
    flight,
    file,
    files,
    duty,
    privateWings,
    company,
    users,
    user,
    companies,
    occupation,
  },
})
