export default {

  namespaced: true,

  state: {
    errors: null,

  },

  getters: {
    getErrors: state => state.errors,
  },

  actions: {
    setErrors({ commit }, errors) {
      commit('SET_VALIDATION_ERRORS', errors)
    },
    clearErrors({ commit }) {
      commit('SET_VALIDATION_ERRORS', null)
    },
  },

  mutations: {
    SET_VALIDATION_ERRORS(state, errors) {
      state.errors = errors
    },

  },
}
