import axios from 'axios'
import { cloneDeep } from 'lodash'
import emitter from '@/service/emitter'
import { transformPersonToBackendFormat } from '@/service/personsService'

export default {

  namespaced: true,

  state: {
    person: [], personStatus: '',
  },

  getters: {
    getPerson: state => state.person,
    getPersonStatus: state => state.personStatus,
  },

  actions: {
    storePerson({ commit, state }, person) {
      commit('SET_PERSON_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.post('api/persons', person).then(res => {
          commit('persons/ADD_PERSON', res.data.data, { root: true })
          commit('SET_PERSON_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_PERSON_STATUS', 'error')
          reject(err)
        })
      })
    },
    deletePerson({ commit, state, rootState }, person) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/persons/${person.id}`)
          .then(res => {
            commit('persons/REMOVE_PERSON', person, { root: true })
            resolve(res)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },

  mutations: {
    SET_PERSON(state, persons) {
      state.persons = persons
    },
    SET_PERSON_STATUS(state, status) {
      state.personsStatus = status
    },
  },
}
