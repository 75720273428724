import Vue from 'vue'
import FlightDialog from './flightDialog'

const Plugin = {
  install(Vue, options) {
    Vue.prototype.$flightDialog = FlightDialog
  },
}

Vue.use(Plugin)
