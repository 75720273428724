<template>
  <v-autocomplete
    v-if="personsStatus === 'success'"
    ref="personSelect"
    :loading="personsStatus === 'loading'"
    outlined
    :items="formattedPersons"
    item-text="firstname"
    item-value="id"
    return-object
    :filter="customFilter"
    label="Add a stored Person to your flight"
    append-outer-icon="mdi-sync"
    @click:append-outer="fetchPersons"
    @change="handleSelection"
  >
    <template v-slot:selection="{ item }">
      <span>{{ item.firstname + ' ' + item.lastname + ' ' + item.birth }}</span>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.firstname + ' ' + item.lastname }}
        </v-list-item-title>

        <v-list-item-subtitle>{{ item.birth }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon>
          <v-icon>mdi-airplane</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
import { transformPersonToFrontendFormat } from '@/service/personsService'

export default {
  name: 'UserPersonSelect',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      persons: 'persons/getPersons',
      personsStatus: 'persons/getPersonsStatus',
    }),
    formattedPersons() {
      const formattedPersons = this.persons
      formattedPersons.push(this.authUser)

      return formattedPersons
    },
  },
  created() {
    this.fetchPersons()
  },
  methods: {
    customFilter(item, queryText, itemText) {
      return (item.firstname.toLowerCase().indexOf(queryText.toLowerCase()) > -1
          || item.lastname.toLowerCase().indexOf(queryText.toLowerCase()) > -1
      )
    },
    fetchPersons() {
      this.$store.dispatch('persons/fetchPersons')
    },
    handleSelection(person) {
      this.$emit('personSelected', person)
      this.$refs.personSelect.reset()
    },
  },
}
</script>

<style scoped>

</style>
