import axios from 'axios'
import { transformFlightToFrontendFormat } from 'mcritfunctionlibrary'
import { cloneDeep } from 'lodash'

export default {

  namespaced: true,

  state: {
    flights: [], status: '',
  },

  getters: {
    getFlights: state => state.flights,
    getFlightsStatus: state => state.status,
  },

  actions: {
    fetchFlightsFromRouteWithParams({ commit, rootState }, { route, params }) {
      commit('SET_FLIGHTS_STATUS', 'loading')
      commit('CLEAR_FLIGHTS')

      return new Promise((resolve, reject) => {
        axios.get(route, { params }).then(res => {
          commit('SET_FLIGHTS', res.data.data.map(flight => transformFlightToFrontendFormat(flight, rootState.schedule.timeZone)))
          commit('SET_FLIGHTS_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_FLIGHTS_STATUS', 'error')
        })
      })
    },
    fetchFlights({ commit, rootState }, { start, end }) {
      commit('SET_FLIGHTS_STATUS', 'loading')
      commit('CLEAR_FLIGHTS')

      return new Promise((resolve, reject) => {
        axios.get('api/flights', {
          params: {
            start,
            end,
          },
        }).then(res => {
          commit('SET_FLIGHTS', res.data.data.map(flight => transformFlightToFrontendFormat(flight, rootState.schedule.timeZone)))
          commit('SET_FLIGHTS_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_FLIGHTS_STATUS', 'error')
        })
      })
    },
  },

  mutations: {
    SET_FLIGHTS(state, flights) {
      state.flights = flights
    },
    SET_FLIGHTS_TZ(state, tz = 'UTC') {
      state.flights = state.flights.map(flight => transformFlightToFrontendFormat(flight, tz))
    },
    SET_FLIGHTS_STATUS(state, status) {
      state.status = status
    },
    CLEAR_FLIGHTS(state) {
      state.flights = []
    },
    REMOVE_FLIGHT(state, flight) {
      const index = state.flights.findIndex(item => item.id === flight.id)
      if (index >= 0) state.flights.splice(index, 1)
    },
    ADD_FLIGHT(state, flight) {
      state.flights.push(cloneDeep(flight))
    },
    UPDATE_FLIGHT(state, flight) {
      const index = state.flights.findIndex(item => item.id === flight.id)
      if (index >= 0) state.flights.splice(index, 1, flight)
    },
  },
}
