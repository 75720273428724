<template>
  <p
    class="title "
    style="line-height: 1.7 !important;"
  >
    <slot />
  </p>
</template>

<script>
export default {
  name: 'BaseText',
}
</script>
