<template>
  <v-card
    flat
    tile
  >
    <v-card-text class="d-flex d-inline-block">
      <div class="d-flex justify-center">
        <v-icon large>
          mdi-account
        </v-icon>
      </div>
      <div class="d-flex flex-column justify-center">
        <span>{{ user.name }}</span>
        <span>{{ user.phone }}</span>
        <span>{{ user.email }}</span>
      </div>
      <div v-if="showCompany">
        Member of:{{ user.company.name }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'UserInfoCard',
  props: {
    user: {
      type: Object,
      required: true,
    },
    showCompany: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
