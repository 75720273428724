<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="{...$attrs,...attrs}"
        v-on="on"
      >
        <slot></slot>
      </v-icon>
    </template>
    <span v-text="tooltip"></span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'IconWithTootip',
  props: {
    tooltip: {
      type: String,
      default: 'tooltip',
    },
  },
}
</script>

<style scoped>

</style>
