import Pusher from 'pusher-js'
import Vue from 'vue'

const pusher = new Pusher('e60e18f3097480a791e0', {
  cluster: 'eu',
  encrypted: true,
  channelAuthorization: {
    endpoint: `${process.env.VUE_APP_API_ENDPOINT}/api/broadcasting/auth`,
    transport: 'ajax',
    params: {},
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    paramsProvider: null,
    headersProvider: null,
    customHandler: null,
  },
})

Vue.prototype.pusher = pusher
