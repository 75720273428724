<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/ima_logo.png')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            IMA INTERFACE
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-group
        v-if="authUser.can.manageFlights"
        title="Admin"
        icon="mdi-cog"
      >
        <nav-menu-link
          v-if="authUser.can.administration"
          title="Companies"
          :to="{name:'Admin.Companies'}"
        ></nav-menu-link>
        <nav-menu-link
          v-if="authUser.can.administration"
          title="Users"
          :to="{name:'Admin.Users'}"
          icon="mdi-account-group"
        ></nav-menu-link>
        <nav-menu-link
          v-if="authUser.can.administration"
          title="Log"
          :to="{name:'Admin.Log'}"
          icon="mdi-view-list"
        ></nav-menu-link>
        <nav-menu-link
          v-if="authUser.can.manageFlights"
          title="PPRs"
          :to="{name:'Admin.PPRs'}"
        />
        <nav-menu-link
          v-if="authUser.can.administration"
          title="Files"
          :to="{name:'Admin.Files'}"
          icon="mdi-file"
        />
        <nav-menu-link
          v-if="authUser.can.manageFlights"
          title="Flights"
          :to="{name:'Admin.Flights'}"
        />
      </nav-menu-group>
      <nav-menu-link
        :title="authUser.name"
        :to="{name:'user'}"
        icon="mdi-account"
      />
      <!--      <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title>-->
      <nav-menu-link
        title="Schedule"
        :to="'/'"
        icon="mdi-calendar"
      />
      <nav-menu-group
        v-if="authUser.company"
        :title="authUser.company.name"
        icon="mdi-account-group"
      >
        <nav-menu-link
          exact
          title="Home"
          :to="{name:'company.show', params:{id:authUser.company.id }}"
          icon="mdi-home-outline"
        />
        <nav-menu-link
          title="Dutyplan"
          :to="{name:'company.dutyplan', params:{id:authUser.company.id }}"
          icon="mdi-account-clock-outline"
        />
        <nav-menu-link
          title="Flights"
          :to="{name:'company.flights', params:{id:authUser.company.id }}"
        />
        <nav-menu-link
          title="Users"
          :to="{name:'company.users', params:{id:authUser.company.id }}"
          icon="mdi-account-group"
        />
        <nav-menu-link
          title="PPRs"
          :to="{name:'company.pprs', params:{id:authUser.company.id }}"
        />
      </nav-menu-group>
      <nav-menu-link
        v-if="authUser.can.createFlights"
        title="My Flights"
        :to="{name:'users.flights', params:{id:authUser.id }}"
        icon="mdi-airplane-clock"
      />
      <nav-menu-link
        v-if="authUser.can.updatePrivateWingsFlights"
        title="Reoccurring Flights"
        :to="'/privateWings'"
        icon="mdi-airplane"
      />
      <nav-menu-link
        title="Public PPR Request"
        :to="{name:'ppr.public'}"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

</style>
