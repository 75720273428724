<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <the-update-button></the-update-button>
  </component>
</template>

<script>
import LayoutDashboard from '@/layouts/dashboard.vue'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutHomepage from '@/layouts/Homepage.vue'
import TheUpdateButton from '@/components/TheUpdateButton'

export default {
  name: 'App',
  components: {
    TheUpdateButton,
    LayoutBlank,
    LayoutDashboard,
    LayoutHomepage,
  },
  computed: {
    resolveLayout() {
      if (this.$route.name === null) return null

      if (this.$route.meta.layout === 'blank') return 'layout-blank'
      if (this.$route.meta.layout === 'homepage') return 'layout-homepage'

      return 'layout-dashboard'
    },
  },
}
</script>

<style scoped>

</style>
