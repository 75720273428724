<template>
  <v-btn
    v-if="isLoggedIn"
    exact
    v-bind="$attrs"
    :icon="icon"
    @click="logout"
  >
    <v-icon>mdi-logout</v-icon>
    <span v-if="!icon">Logout</span>
  </v-btn>
</template>

<script>
export default {
  name: 'TheLogoutButton',
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    logout() {
      this.$dialog.requestConfirmation('Logout ?').then(() => {
        this.$router.push('/login').then(() => {
          this.$store.dispatch('auth/logout')
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
