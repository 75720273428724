import axios from 'axios'
import { cloneDeep } from 'lodash'

export default {

  namespaced: true,

  state: {
    files: [], status: '',
  },

  getters: {
    getFiles: state => state.files,
    getFilesStatus: state => state.status,
  },

  actions: {
    fetchFiles({ commit, state }, params) {
      commit('CLEAR_FILES')
      commit('SET_FILES_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get('/api/files', params)
          .then(res => {
            commit('SET_FILES', res.data.data)
            commit('SET_FILES_STATUS', 'success')
            resolve(res)
          }).catch(err => {
            reject(err)
            commit('SET_FILES_STATUS', 'error')
          })
      })
    },
    fetchUserFiles({ commit, rootState }, user) {
      commit('CLEAR_FILES')
      commit('SET_FILES_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get(`/api/users/${user.id}/files`)
          .then(res => {
            commit('SET_FILES', res.data.data)
            commit('SET_FILES_STATUS', 'success')
            resolve(res)
          }).catch(err => {
            reject(err)
            commit('SET_FILES_STATUS', 'error')
          })
      })
    },
  },

  mutations: {
    CLEAR_FILES(state) {
      state.files = []
    },
    SET_FILES(state, files) {
      state.files = files
    },
    SET_FILES_STATUS(state, status) {
      state.status = status
    },
    REMOVE_FILE(state, flight) {
      const index = state.files.findIndex(item => item.id === flight.id)
      if (index >= 0) state.files.splice(index, 1)
    },
    ADD_FILE(state, flight) {
      const index = state.files.findIndex(item => item.id === flight.id)
      if (index >= 0) state.files.splice(index, 1, flight)
      else state.files.push(cloneDeep(flight))
    },
  },
}
