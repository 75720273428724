// VIEWS

// MIDDLEWARE
import auth from '@/middleware/auth'

export default [
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Auth/Register'),
    meta: {
      layout: 'blank',
    },
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Auth/Login'),
    meta: {
      layout: 'blank',
    },
  }, {
    path: '/password/reset',
    name: 'ResetPassword',
    component: () => import('@/views/Auth/ResetPassword'),
    meta: {
      layout: 'blank',
    },
  }, {
    path: '/password/forgot',
    name: 'ForgotPassword',
    component: () => import('@/views/Auth/ForgotPassword'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/Errors/Maintenance'),
    meta: {
      layout: 'blank',
    },
  }, {
    path: '/ppr',
    name: 'ppr.public',
    component: () => import('@/views/public_ppr'),
    meta: {
      layout: 'homepage',
    },
  },
  {
    path: '/',
    name: 'Schedule',
    component: () => import('@/views/Schedule'),
    meta: {
      middleware: auth,
    },

  },
  {
    path: '/admin',
    component: () => import('@/views/Admin/Admin'),
    meta: {
      middleware: auth,
    },
    children: [
      {
        path: 'companies',
        name: 'Admin.Companies',
        component: () => import('@/views/Admin/components/AdminCompanies'),
        meta: {
          middleware: auth,
        },
      }, {
        path: 'users',
        name: 'Admin.Users',
        component: () => import('@/views/Admin/components/AdminUsers'),
        meta: {
          middleware: auth,
        },
      }, {
        path: 'pprs',
        name: 'Admin.PPRs',
        component: () => import('@/views/Admin/components/AdminPPRs'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'files',
        name: 'Admin.Files',
        component: () => import('@/views/Admin/components/AdminFiles'),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'flights',
        name: 'Admin.Flights',
        component: () => import('@/views/Admin/components/AdminFlights'),
        meta: {
          middleware: [auth],
        },
      }, {
        path: 'log',
        name: 'Admin.Log',
        component: () => import('@/views/Admin/components/AdminLog'),
        meta: {
          middleware: [auth],
        },
      },
    ],
  }, {
    path: '/user',
    name: 'user',
    component: () => import('@/views/User/UserProfile'),
    meta: {
      middleware: auth,
    },
  }, {
    path: '/users/:id/flights',
    name: 'users.flights',
    component: () => import('@/views/Flights/UserFlights'),
    meta: {
      middleware: [auth],
    },
  }, {
    path: '/companies/:id',
    component: () => import('@/views/Company/CompanyContainer'),
    meta: {
      middleware: auth,
    },
    children: [
      {
        path: '',
        name: 'company.show',
        component: () => import('@/views/Company/CompanyShow'),
      },
      {
        path: 'dutyplan',
        name: 'company.dutyplan',
        component: () => import('@/views/Company/CompanyDutyPlan'),
      }, {
        path: 'flights',
        name: 'company.flights',
        component: () => import('@/views/Company/CompanyFlights'),
      },
      {
        path: 'users',
        name: 'company.users',
        component: () => import('@/views/Company/CompanyUsers'),
      },
      {
        path: 'pprs',
        name: 'company.pprs',
        component: () => import('@/views/Company/CompanyPPRs'),
      },

    ],
  }, {
    path: '/privateWings',
    name: 'PrivateWings',
    component: () => import('@/views/PrivateWings/PrivateWings'),
    meta: {
      middleware: [auth],
    },
  },
]
