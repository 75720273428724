<template>
  <v-alert
    v-if="validationErrors"
    prominent
    type="error"
    outlined
  >
    <ul
      v-for="(v, k) in validationErrors"
      :key="k"
      class="d-flex justify-center align-center"
    >
      <li
        v-for="error in v"
        :key="error"
        class="text-sm"
      >
        {{ error }}
      </li>
    </ul>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ValidationErrorsDisplay',
  computed: {
    ...mapGetters({
      validationErrors: 'validation/getErrors',

    }),
  },
}
</script>

<style scoped>

</style>
