<template>
  <v-dialog
    v-model="dialog.showBool"
    persistent
    overlay-color="grey"
    overlay-opacity="0.7"
    max-width="300px"
  >
    <v-card outlined>
      <v-card-title>
        {{ dialog.title }}
      </v-card-title>

      <v-card-text>
        {{ dialog.text }}
        <div v-if="dialog.type === 'prompt'">
          <v-textarea
            v-model="dialog.message"
            outlined
            dense
          ></v-textarea>
        </div>
        <div v-if="dialog.type === 'choice'">
          <v-radio-group v-model="dialog.choice">
            <v-radio
              v-for="(option,i ) in dialog.options"
              :key="i"
              :label="option.text"
              :value="option.value"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <div v-if="dialog.type === 'confirm'">
          <v-btn
            text
            color="success"
            @click="dialog.confirm()"
          >
            Affirm
          </v-btn>
          <v-btn
            text
            color="error"
            @click="dialog.cancel()"
          >
            Cancel
          </v-btn>
        </div>

        <div v-if="dialog.type === 'alert'">
          <v-btn @click="dialog.confirm()">
            Okay
          </v-btn>
        </div>

        <div v-if="dialog.type === 'prompt'">
          <v-btn @click="dialog.confirm(dialog.message)">
            Okay
          </v-btn>
          <v-btn
            text
            color="error"
            @click="dialog.cancel()"
          >
            Cancel
          </v-btn>
        </div>

        <div v-if="dialog.type === 'decide'">
          <v-btn @click="dialog.confirm()">
            {{ dialog.resolveText }}
          </v-btn>
          <v-btn
            text
            color="error"
            @click="dialog.cancel()"
          >
            {{ dialog.rejectText }}
          </v-btn>
        </div>
        <div v-if="dialog.type === 'choice'">
          <v-btn
            :disabled="!dialog.choice"
            @click="dialog.confirm(dialog.choice)"
          >
            {{ dialog.resolveText }}
          </v-btn>
          <v-btn
            text
            color="error"
            @click="dialog.cancel()"
          >
            {{ dialog.rejectText }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',

  data() {
    return {
      dialog: this.$dialog,
    }
  },

}
</script>
