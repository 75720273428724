import axios from 'axios'

function defaultCompany() {
  return {}
}

export default {

  namespaced: true,

  state: {
    company: defaultCompany(),
    companyStatus: '',
    companyOccupations: [],
    occupation: {},
    users: [],
    usersStatus: '',
  },

  getters: {
    getCompany: state => state.company,
    getCompanyUsers: state => state.users,
    getCompanyUsersStatus: state => state.usersStatus,
    getCompanyOccupations: state => state.companyOccupations,
    getCompanyStatus: state => state.companyStatus,
  },

  actions: {

    fetchCompanyUsers({ commit, state }, companyID) {
      commit('SET_COMPANY_USERS_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get(`api/companies/${companyID}/users`).then(res => {
          commit('SET_COMPANY_USERS', res.data.data)
          commit('SET_COMPANY_USERS_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_COMPANY_USERS_STATUS', 'error')
        })
      })
    },

    fetchCompanyByID({ commit, state }, companyID) {
      commit('SET_COMPANY_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get(`api/companies/${companyID}`).then(res => {
          commit('SET_COMPANY', res.data.data)
          commit('SET_COMPANY_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_COMPANY_STATUS', 'error')
        })
      })
    },
    createCompany({ commit, state }, company) {
      commit('SET_COMPANY_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.post('api/companies', company).then(res => {
          commit('SET_COMPANY_STATUS', 'success')
          commit('companies/ADD_COMPANY_TO_COMPANIES', res.data.data, { root: true })
          resolve(res)
        }).catch(err => {
          commit('SET_COMPANY_STATUS', 'error')
          reject(err)
        })
      })
    },
    updateCompany({ commit, state }, company) {
      commit('SET_COMPANY_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.patch(`api/companies/${company.id}`, company).then(res => {
          commit('SET_COMPANY', res.data.data)
          commit('companies/UPDATE_COMPANY', res.data.data, { root: true })
          commit('SET_COMPANY_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_COMPANY_STATUS', 'error')
          reject(err)
        })
      })
    },
    fetchCompanyOccupations({ commit }, companyID) {
      axios.get(`/api/companies/${companyID}/occupations`).then(res => {
        commit('SET_COMPANY_OCCUPATIONS', res.data.data)
      }).catch(err => {
        console.log(err)
      })
    },
    saveCompanyOccupation({ commit }, { companyID, occupation }) {
      axios.post(`/api/companies/${companyID}/occupations`, occupation).then(res => {
        commit('ADD_OCCUPATION', res.data.data)
        commit('CLEAR_OCCUPATION')
      }).catch(err => {
        console.log(err)
      })
    },
  },

  mutations: {
    SET_COMPANY(state, company) {
      state.company = company
    },
    SET_COMPANY_STATUS(state, status) {
      state.companyStatus = status
    },
    SET_COMPANY_USERS(state, users) {
      state.users = users
    },
    SET_COMPANY_USERS_STATUS(state, status) {
      state.usersStatus = status
    },
    SET_COMPANY_OCCUPATIONS(state, occupations) {
      state.companyOccupations = occupations
    },
    ADD_OCCUPATION(state, occupation) {
      state.companyOccupations.push(occupation)
    },
    CLEAR_COMPANY(state) {
      state.company = defaultCompany()
    },
    CLEAR_OCCUPATION(state) {
      state.occupation = {}
    },
    UPDATE_OCCUPATION(state, occupation) {
      const index = state.companyOccupations.findIndex(item => item.id === occupation.id)
      if (index >= 0) state.companyOccupations.splice(index, 1, occupation)
    },
  },
}
