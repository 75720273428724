<template>
  <v-select
    ref="field"
    outlined
    dense
    label="Company"
    clearable
    :loading="companiesStatus === 'loading'"
    v-bind="$attrs"
    item-text="name"
    item-value="id"
    :value="model"
    :items="filteredCompanies"
    persistent-hint
    v-on="$listeners"
  >
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompanySelect',
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      companies: 'companies/getCompanies',
      companiesStatus: 'companies/getCompaniesStatus',
    }),
    filteredCompanies() {
      // Return only Companies with Flight Management
      return this.companies.filter(company => company.has_airplanes)
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('errors', this.$refs.field.validate())
      },
    },
  },
  created() {
    this.$store.dispatch('companies/fetchCompanies')
  },

}
</script>

<style scoped>

</style>
