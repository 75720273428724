<template>
  <v-card
    outlined
    color="transparent"
  >
    <v-card-text>
      <v-row
        v-if="withContact"
        dense
      >
        <v-col
          cols="12"
          class="text-center "
        >
          <v-divider></v-divider>
          <span v-if="flight.contact_name">{{ flight.contact_name }} </span>
          <span v-if="flight.contact_email">- Mail: {{ flight.contact_email }} </span>
          <span v-if="flight.contact_phone">- Phone: {{ flight.contact_phone }} </span>
          <span v-if="flight.contact_fax">- Fax: {{ flight.contact_fax }} </span>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between">
        <v-col class="d-flex flex-column align-center">
          <div class="text-h5">
            {{ flight.operator }}
          </div>
          <div>
            <span v-if="flight.id">Flight: {{ flight.id }}</span><br>
            <span>{{ flight.ac_call || 'AC Call Missing' }}</span> <span>{{ ' ( ' + (flight.ac_type || 'AC Type') + ' )' }}</span>  <br>
            <span v-if="flight.created_at">{{ 'Received: ' + flight.created_at }}</span> <br>
            <icon-with-tootip
              v-if="flight.flight_status"
              small
              :color="getStatusColor(flight.flight_status)"
              :tooltip="'Flight Status: '+ flight.flight_status"
            >
              mdi-circle
            </icon-with-tootip>
            <v-btn
              v-if="flight.can.update"
              icon
              @click="showFlightDialog(flight)"
            >
              <icon-with-tootip
                small
                tooltip="Edit Flight"
              >
                mdi-playlist-edit
              </icon-with-tootip>
            </v-btn>
            <v-btn
              v-if="flight.can.update"
              icon
              @click="copyFlight(flight)"
            >
              <icon-with-tootip
                small
                tooltip="Use Flight as Template"
              >
                mdi-content-copy
              </icon-with-tootip>
            </v-btn>
            <v-btn
              v-if="flight.can.delete"
              icon
              @click="deleteFlight(flight)"
            >
              <icon-with-tootip
                small
                color="error"
                tooltip="Delete Flight"
              >
                mdi-delete
              </icon-with-tootip>
            </v-btn>
          </div>
        </v-col>
        <v-col class="d-flex d-block flex-wrap justify-center align-center justify-space-between">
          <div class="d-flex flex-column justify-center ma-2">
            <div class="text-h5">
              {{ flight.ad_dep }}
            </div>
            <div>{{ flight.dep_date }}</div>
            <div> {{ flight.dep_time }}</div>
          </div>

          <div class="d-flex flex-column align-center justify-center">
            <div> {{ flight.ifr ? ' IFR' : 'VFR' }}</div>
            <div>
              <v-icon large>
                mdi-arrow-right
              </v-icon>
            </div>
            <div> {{ flight.landings ? flight.landings + ' LDGS ' : null }}</div>
          </div>
          <div class="d-flex flex-column justify-center">
            <div class="text-h5">
              {{ flight.ad_dest }}
            </div>
            <div>{{ flight.arr_date }}</div>
            <div>{{ flight.arr_time }}</div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          v-if="flight.remarks && remarks"
          cols="12"
        >
          <v-divider></v-divider>
          <span class="warning--text">{{ 'REMARKS:' + flight.remarks }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { getStatusColor } from '@/service/flightService'
import IconWithTootip from '@/components/base/IconWithTootip'

export default {
  name: 'ShowSingleFlightSheet',
  components: { IconWithTootip },
  props: {
    flight: {
      type: Object,
      required: true,
    },
    actionButtonText: {
      type: [String, Number],
      default: 'ActionButton',
    },
    actionButton: {
      type: Boolean,
      default: false,
    },
    withContact: {
      type: Boolean,
      default: false,
    },
    remarks: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getStatusColor,
    deleteFlight(flight) {
      this.$dialog.requestConfirmation('Delete PPR ?')
        .then(() => {
          this.$store.dispatch('flight/deleteFlight', flight)
        })
    },
    copyFlight(flight) {
      this.$store.dispatch('flight/setFlightAsTemplate', flight)
      if (flight.user && flight.user.id) {
        this.$flightDialog.createForUser(flight.user)
      } else {
        this.$flightDialog.create()
      }
    },
    showFlightDialog(flight) {
      this.$store.dispatch('flight/fetchFlightById', flight.id).then(
        () => {
          this.$flightDialog.edit()
        },
      )
    },
  },
  computed: {
    ...mapGetters({
      timeZone: 'schedule/timeZone',
    }),
  },
}
</script>

<style scoped>

</style>
