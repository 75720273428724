class PPRDialog {
  constructor() {
    this.show = false
    this.title = ''
    this.action = ''
    this.target = 'FlightController'
    this.user = null
    this.company = null
    this.tab = 0
  }

  edit() {
    this.title = 'Edit Leg'
    this.action = 'edit'
    this.show = true
  }

  create() {
    this.title = 'New Leg'
    this.action = 'create'
    this.show = true
  }

  close() {
    this.show = false
    this.tab = 0
  }
}

export default new PPRDialog()
