import axios from 'axios'
import { transformFlightToFrontendFormat } from 'mcritfunctionlibrary'
import emitter from '@/service/emitter'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

function defaultFlight() {
  return {
    id: uuidv4(),
    ad_dep: 'ETSI',
    ad_dest: 'ETSI',
    wx: true,
    ifr: true,
    landings: 1,
    can: [],
  }
}

export default {

  namespaced: true,

  state: {
    flight: null,
    status: '',
  },

  getters: {
    getFlight: state => state.flight,
    getFlightStatus: state => state.status,
  },

  actions: {
    generateCustomsMail({ commit, state }, flight) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/flights/${flight.id}/generateCustomsMail`)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    fetchFlightById({ commit, rootState }, id) {
      commit('SET_FLIGHT_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get(`api/flights/${id}`).then(res => {
          commit('SET_FLIGHT', transformFlightToFrontendFormat(res.data.data, rootState.schedule.timeZone))
          commit('flights/UPDATE_FLIGHT', transformFlightToFrontendFormat(res.data.data, rootState.schedule.timeZone), { root: true })
          commit('SET_FLIGHT_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_FLIGHT_STATUS', 'error')
        })
      })
    },
    reloadAndReplaceFlightByIdSilently({ commit, rootState }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`api/flights/${id}`).then(res => {
          commit('flights/UPDATE_FLIGHT', transformFlightToFrontendFormat(res.data.data, rootState.schedule.timeZone), { root: true })
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    setFlightAsTemplate({ state, commit, rootState }, flight) {
      commit('SET_FLIGHT_STATUS', 'loading')
      const template = cloneDeep(flight)
      template.id = uuidv4()
      template.remarks = ''
      template.gpu = false
      template.lifter = false
      template.stairs = false
      template.hangar = false
      template.refueling = false
      template.rental_car = false
      delete template.assigned_users
      delete template.flight_status
      delete template.airport_approval
      delete template.customs_status
      delete template.activities
      commit('SET_FLIGHT', template)
      commit('SET_FLIGHT_STATUS', 'success')
    },
    storeFlight({ commit, state }, flight) {
      commit('SET_FLIGHT_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.post('api/flights', flight).then(res => {
          emitter.emit('flightCreated')
          commit('SET_FLIGHT_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_FLIGHT_STATUS', 'error')
          reject(err)
        })
      })
    },
    storeUserFlight({ commit, state }, { user, flight }) {
      commit('SET_FLIGHT_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.post(`api/users/${user.id}/flights`, flight).then(res => {
          emitter.emit('flightCreated')
          commit('SET_FLIGHT_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_FLIGHT_STATUS', 'error')
          reject(err)
        })
      })
    },
    storeCompanyFlight({ commit, state }, { company, flight }) {
      commit('SET_FLIGHT_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.post(`api/companies/${company.id}/flights`, flight).then(res => {
          emitter.emit('flightCreated')
          commit('SET_FLIGHT_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_FLIGHT_STATUS', 'error')
          reject(err)
        })
      })
    },
    updateFlight({ commit, rootState }, flight) {
      commit('SET_FLIGHT_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.patch(`api/flights/${flight.id}`, flight).then(res => {
          commit('SET_FLIGHT_STATUS', 'success')
          commit('flights/UPDATE_FLIGHT', transformFlightToFrontendFormat(res.data.data, rootState.schedule.timeZone), { root: true })
          resolve(res)
        }).catch(err => {
          commit('SET_FLIGHT_STATUS', 'error')
          reject(err)
        })
      })
    },
    deleteFlight({ commit, state }, flight) {
      return new Promise((resolve, reject) => {
        axios.delete(`api/flights/${flight.id}`, { params: { deleteAssociateFlight: flight.delete_assoc } }).then(res => {
          // Remove the Flight from the Store as well
          commit('flights/REMOVE_FLIGHT', flight, { root: true })
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    setDefaultFlightContact({ commit, rootState }) {
      commit('SET_FLIGHT_CONTACT', rootState.auth.user)
    },
    addUserToFlightPobs({ commit }, user) {
      commit('ADD_PERSON_TO_FLIGHT', user)
    },
    createFlight({ commit }) {
      commit('SET_FLIGHT', {})
      commit('SET_FLIGHT_STATUS', 'success')
    },
  },

  mutations: {
    SET_FLIGHT_CONTACT(state, authUser) {
      state.flight.contact_name = authUser.flight_responsibility === 'user' ? authUser.name : authUser?.company?.name
      state.flight.contact_phone = authUser.flight_responsibility === 'user' ? authUser.phone : authUser?.company?.phone
      state.flight.contact_email = authUser.flight_responsibility === 'user' ? authUser.email : authUser?.company?.email
    },
    SET_FLIGHT(state, flight) {
      state.flight = cloneDeep(flight)
    },
    CLEAR_FLIGHT(state) {
      state.flight = defaultFlight()
    },
    SET_FLIGHT_STATUS(state, status) {
      state.status = status
    },
    ADD_PERSON_TO_FLIGHT(state, person) {
      if (!state.flight.pobs) {
        // Workaround for Reactivity
        state.flight = { ...state.flight, pobs: [] }
      }

      // Neded because Switch is not initialized Correct
      const defaultPerson = { crew: false }
      person = { ...defaultPerson, ...person }
      state.flight.pobs.push(cloneDeep(person))
    },
    ADD_FILE_TO_FLIGHT(state, file) {
      state.flight = { ...state.flight, file_id: file.id, file }
    },
    REMOVE_PERSON_FROM_FLIGHT(state, index) {
      state.flight.pobs.splice(index, 1)
    },
  },
}
