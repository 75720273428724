import axios from 'axios'

export default {

  namespaced: true,

  state: {
    user: null,
    status: '',
  },

  getters: {
    getUser: state => state.user,
    getUserStatus: state => state.status,
  },

  actions: {
    fetchUserById({ state, commit }, id) {
      commit('SET_USER_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get(`api/users/${id}`).then(res => {
          commit('SET_USER', res.data.data)
          commit('SET_USER_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_USER_STATUS', 'error')
        })
      })
    },
    updateUser({ commit, state }, user) {
      commit('SET_USER_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.patch(`api/users/${user.id}`, user).then(res => {
          commit('SET_USER_STATUS', 'success')
          commit('users/UPDATE_USER', res.data.data, { root: true })
          resolve(res)
        }).catch(err => {
          commit('SET_USER_STATUS', 'error')
          reject(err)
        })
      })
    },
    storeUser({ commit, state }, user) {
      commit('SET_USER_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.post('api/users/', user).then(res => {
          commit('SET_USER', res.data.data)
          commit('SET_USER_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_USER_STATUS', 'error')
          reject(err)
        })
      })
    },
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_USER_STATUS(state, status) {
      state.status = status
    },
  },
}
