class FlightDialog {
  constructor() {
    this.show = false
    this.title = ''
    this.action = ''
    this.target = 'FlightController'
    this.user = null
    this.company = null
    this.tab = 0
  }

  edit() {
    this.target = 'FlightController'
    this.title = 'Edit Flight'
    this.action = 'edit'
    this.show = true
    this.user = null
  }

  create() {
    this.target = 'FlightController'
    this.title = 'Create Flight'
    this.action = 'create'
    this.show = true
    this.user = null
  }

  createForUser(user) {
    this.target = 'UserFlightController'
    this.title = `Create Flight for ${user.name}`
    this.action = 'create'
    this.show = true
    this.user = user
  }

  createForCompany(company) {
    this.target = 'CompanyFlightController'
    this.title = `Create Flight for ${company.name}`
    this.action = 'create'
    this.show = true
    this.company = company
  }

  close() {
    this.show = false
    this.tab = 0
  }
}

export default new FlightDialog()
