import axios from 'axios'
import { cloneDeep } from 'lodash'
import { transformFlightToFrontendFormat } from 'mcritfunctionlibrary'
import { transformPersonToFrontendFormat } from '@/service/personsService'

export default {

  namespaced: true,

  state: {
    persons: [], personsStatus: '',
  },

  getters: {
    getPersons: state => state.persons,
    getPersonsStatus: state => state.personsStatus,
  },

  actions: {
    fetchPersons({ commit, state }) {
      commit('SET_PERSONS_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get('/api/persons')
          .then(res => {
            commit('SET_PERSONS', res.data.data.map(person => transformPersonToFrontendFormat(person)))
            commit('SET_PERSONS_STATUS', 'success')
            resolve(res)
          }).catch(err => {
            reject(err)
            commit('SET_PERSONS_STATUS', 'error')
          })
      })
    },
  },

  mutations: {
    SET_PERSONS(state, persons) {
      state.persons = persons
    },
    SET_PERSONS_STATUS(state, status) {
      state.personsStatus = status
    },
    REMOVE_PERSON(state, person) {
      const index = state.persons.findIndex(item => item.id === person.id)
      if (index >= 0) state.persons.splice(index, 1)
    },
    ADD_PERSON(state, person) {
      const index = state.persons.findIndex(item => item.id === person.id)
      if (index >= 0) state.persons.splice(index, 1, person)
      else state.persons.push(cloneDeep(person))
    },
  },
}
