import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { frontEndFormats } from '@/config'

const Validation = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          globalRules: {
            // eslint-disable-next-line no-control-regex
            validEmail: v => !v || /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21-\x5A\x53-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])+)\])/.test(v) || 'E-mail must be valid',
            dateNowOrLater: value => moment(value, frontEndFormats.DATE_FORMAT, true).diff(moment(), 'day') >= 0 || !value || 'Date lies in past',
            dateNowOrEarlier: value => moment(value, frontEndFormats.DATE_FORMAT, true).diff(moment(), 'day') <= 0 || !value || 'Date lies in future',
            timeAfter: (value, timeString) => moment(value, frontEndFormats.TIME_FORMAT, true).isAfter(moment(timeString, frontEndFormats.TIME_FORMAT, true)) || !value || `Time must be greater than ${timeString}`,
            timeBefore: (value, timeString) => moment(value, frontEndFormats.TIME_FORMAT, true).isBefore(moment(timeString, frontEndFormats.TIME_FORMAT, true)) || !value || `Time must be smaller than ${timeString}`,
            timeBetween: (value, start, end) => (moment(value, frontEndFormats.TIME_FORMAT, true).isSameOrBefore(moment(end, frontEndFormats.TIME_FORMAT, true)) && moment(value, frontEndFormats.TIME_FORMAT, true).isSameOrAfter(moment(start, frontEndFormats.TIME_FORMAT, true))) || !value || `Time must be between ${start} and ${end}`,
            validDate: value => (moment(value, frontEndFormats.DATE_FORMAT, true).isValid() || !value) || 'Not a valid Date(DD.MM.YYYY)',
            validDateTime: value => (moment(value, `${frontEndFormats.DATE_FORMAT} ${frontEndFormats.TIME_FORMAT}`, true).isValid() || !value) || 'Not a valid DateTime(DD.MM.YYYY HH:mm)',
            validLandingsCount: value => (value > 0 && value < 256) || 'Landings must be between 1 and 255',
            validTime: value => (moment(value, frontEndFormats.TIME_FORMAT, true).isValid() || !value) || 'Not a valid Time(HH:mm)',
            required: v => !!v || 'Input required',
          },
        }
      },
      computed: {
        ...mapGetters({
          authUser: 'auth/user',
          isLoggedIn: 'auth/check',
        }),
      },
    })
  },
}

Vue.use(Validation)
