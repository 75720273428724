import Vue from 'vue'
import moment from 'moment'
import { frontEndFormats, backEndFormats } from '@/config'

Vue.filter('formatDateString', value => {
  if (!value) {
    return ''
  }

  return moment.utc(String(value), backEndFormats.DATE_FORMAT).format(frontEndFormats.DATE_FORMAT)
})

Vue.filter('getWeekRange', value => {
  if (!value) {
    return ''
  }

  return moment.utc(String(value), backEndFormats.DATE_FORMAT).weekday(1).format('dddd DD.MM.YYYY')
})

Vue.filter('placeHolder', value => {
  if (!value) {
    return 'no data'
  }

  return value
})
