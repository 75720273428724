import axios from 'axios'

export default {

  namespaced: true,

  state: {
    duty: {},
    dutyStatus: '',
    duties: [],
    dutiesStatus: '',
  },
  getters: {
    getDuties: state => state.duties,
    getDutiesStatus: state => state.dutiesStatus,
    getDuty: state => state.duty,
    getDutyStatus: state => state.dutyStatus,
  },

  actions: {

    storeDuty({ commit, state }, { company, duty }) {
      commit('SET_DUTY_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.post(`/api/companies/${company.id}/duties`, duty)
          .then(res => {
            commit('SET_DUTY_STATUS', 'success')
            resolve(res)
          })
          .catch(err => {
            commit('SET_DUTY_STATUS', 'error')
            reject(err)
          })
      })
    },
    updateDuty({ commit, state }, duty) {
      commit('SET_DUTY_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.patch(`/api/duties/${duty.id}`, duty)
          .then(res => {
            commit('SET_DUTY_STATUS', 'success')
            resolve(res)
          })
          .catch(err => {
            commit('SET_DUTY_STATUS', 'error')
            reject(err)
          })
      })
    },
    deleteDuty({ commit, state }, { duty, params = {} }) {
      commit('SET_DUTY_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.delete(`/api/duties/${duty.id}`, { data: params })
          .then(res => {
            commit('SET_DUTY_STATUS', 'success')
            resolve(res)
          })
          .catch(err => {
            commit('SET_DUTY_STATUS', 'error')
            reject(err)
          })
      })
    },
    fetchDuties({ commit, state }, params) {
      commit('SET_DUTIES_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get('api/duties', {
          params,
        }).then(res => {
          commit('SET_DUTIES', res.data.data)
          commit('SET_DUTIES_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_DUTIES', [])
          reject(err)
          commit('SET_DUTIES_STATUS', 'error')
        })
      })
    },
    fetchDuty({ commit, state }, id) {
      commit('SET_DUTY_STATUS', 'loading')
      console.log(`Trying to fetch Duty  ${id}`)

      return new Promise((resolve, reject) => {
        axios.get(`api/duties/${id}`).then(res => {
          const duty = res.data.data
          commit('SET_DUTY', duty)
          commit('SET_DUTY_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_DUTY', [])
          reject(err)
          commit('SET_DUTY_STATUS', 'error')
        })
      })
    },
  },

  mutations: {
    SET_DUTIES(state, duties) {
      state.duties = duties
    },
    SET_DUTIES_STATUS(state, status) {
      state.dutiesStatus = status
    },
    SET_DUTY(state, duty) {
      state.duty = duty
    },
    SET_DUTY_STATUS(state, status) {
      state.dutyStatus = status
    },
  },
}
