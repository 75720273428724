<template>
  <v-dialog
    v-model="dialog.show"
    max-width="1240"
  >
    <v-card
      v-if="dialog.show"
      outlined
      min-height="700px"
      :loading="flightStatus === 'loading'"
      class="card-outter"
    >
      <v-card-text class="pa-0 ma-0">
        <v-toolbar
          dense
          @dblclick="toggleDebug"
        >
          <h1 class="mx-auto ">
            <span v-if="dialog.action==='edit'">
              <v-icon :color="getStatusColor(flight.flight_status)">
                mdi-checkbox-blank-circle
              </v-icon>
            </span>
            {{ dialog.title }}
          </h1>
          <template v-slot:extension>
            <v-tabs
              v-model="dialog.tab"
              color="primary"
              centered
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab>
                FlightData
                <v-icon :color="flightFormValid ? null :'error'">
                  mdi-airplane
                </v-icon>
              </v-tab>
              <v-tab>
                POBs
                <v-icon :color="pobFormValid ? null :'error'">
                  mdi-account-box
                </v-icon>
              </v-tab>
              <v-tab>
                Information
                <v-icon>mdi-information-outline</v-icon>
              </v-tab>
              <v-tab v-if="dialog.action === 'edit'">
                LOG
                <v-icon>mdi-code-json</v-icon>
              </v-tab>
              <v-tab>
                Handling
                <v-icon :color="contactFormValid ? null :'error'">
                  mdi-airport
                </v-icon>
              </v-tab>
              <v-tab v-if="errors">
                Errors
                <v-icon color="error">
                  mdi-alert
                </v-icon>
              </v-tab>
              <v-tab v-if="authUser.can.manageFlights">
                FBO
                <v-icon color="success">
                  mdi-shield-crown-outline
                </v-icon>
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <div class="d-flex flex-column justify-center align-center">
          <div
            v-if="flightNeedsCustomsDeclaration"
            class="ma-2"
          >
            <v-icon color="error">
              mdi-alert
            </v-icon>
            Customs Declaration
          </div>
          <div
            v-if="!contactFormValid"
            class="ma-2"
          >
            <v-icon color="error">
              mdi-alert
            </v-icon>
            Contact Data is missing - please switch to HANDLING Tab and provide missing parameters.
          </div>
          <div
            v-if="flight.ifr"
            class="ma-2"
          >
            <v-icon color="primary">
              mdi-alert
            </v-icon>
            IFR / PPR Fees might apply outside opening hours. Weather Service mandatory
          </div>
          <div
            v-if="timeZone.toUpperCase() !== 'UTC'"
            class="ma-2"
          >
            <v-icon color="error">
              mdi-alert
            </v-icon>
            {{ 'TZ: ' + timeZone }}
          </div>
        </div>
      </v-card-text>

      <v-tabs-items
        v-model="dialog.tab"
        class="pt-4"
      >
        <!--        Flight-->
        <v-tab-item eager>
          <v-card-text>
            <v-form
              ref="flightForm"
              v-model="flightFormValid"
            >
              <v-row v-if="debug">
                {{ flight }}
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-combobox
                    v-model="flight.operator"
                    clearable
                    outlined
                    dense
                    :items="['INflight GmbH','PrivateWings', 'Airbus Defence and Space', 'Airbus Helicopter', 'SFG WTD 61' ,'Volkswagen AirService']"
                    label="Operator"
                  ></v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.ac_type"
                    outlined
                    dense
                    label="Aircraft Type"
                    :rules="[globalRules.required]"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.ac_call"
                    outlined
                    dense
                    label="Registration / FlightNumber"
                    :rules="[globalRules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <airport-text-field
                    v-model="flight.ad_dep"
                    swapping
                    :rules="[globalRules.required, airportInputRule]"
                    label="Aerodrome of Departure"
                    @swap="swapRoute"
                  ></airport-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.dep_date"
                    v-mask="'##.##.20##'"
                    clearable
                    outlined
                    dense
                    :rules="[globalRules.validDate, dateInputRule]"
                    label="Departure Date"
                    @keyup="handleDateKeyInput($event,'dep_date')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.dep_time"
                    v-mask="'##:##'"
                    clearable
                    outlined
                    dense
                    :rules="[globalRules.validTime]"
                    label="Departure Time"
                    @keyup="handleTimeKeyInput($event,'dep_time')"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <airport-text-field
                    v-model="flight.ad_dest"
                    swapping
                    :rules="[globalRules.required, airportInputRule]"
                    label="Destination Aerodrome"
                    @swap="swapRoute"
                  ></airport-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.arr_date"
                    v-mask="'##.##.20##'"
                    outlined
                    clearable
                    dense
                    :rules="[globalRules.validDate, dateInputRule]"
                    label="Arrival Date"
                    @keyup="handleDateKeyInput($event,'arr_date')"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.arr_time"
                    v-mask="'##:##'"
                    outlined
                    clearable
                    dense
                    :rules="[globalRules.validTime]"
                    label="Arrival Time"
                    @keyup="handleTimeKeyInput($event,'arr_time')"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-if="flight.ad_dest != null && flight.ad_dest.toUpperCase() === 'ETSI'"
                    v-model="flight.landings"
                    outlined

                    dense
                    label="# Landings"
                    :rules="[globalRules.validLandingsCount]"
                    class="shrink"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.dz_start_time"
                    v-mask="'##:##'"
                    outlined
                    dense
                    label="Dropping Area Start"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.dz_end_time"
                    v-mask="'##:##'"
                    outlined
                    dense
                    label="Dropping Area End"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    v-model="flight.apron"
                    outlined
                    dense
                    label="Apron"
                    :rows="3"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    v-model="flight.remarks"
                    outlined
                    dense
                    label="Remarks"
                    :rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col
                  v-if="authUser.can.manageFlights"
                  class="d-flex align-center justify-center"
                  cols="12"
                  md="4"
                >
                  <v-select
                    v-model="flight.airport_approval"
                    outlined
                    clearable
                    hide-details
                    dense
                    :items="airportApprovalItems"
                    item-text="name"
                    item-value="value"

                    label="Airport Approval"
                  />
                </v-col>
                <v-col
                  v-if="authUser.can.manageFlights"
                  class="d-flex align-center justify-center"
                  cols="12"
                  md="4"
                >
                  <v-select
                    v-model="flight.flight_status"
                    outlined
                    hide-details
                    dense
                    :items="flightStatusItems"
                    item-text="name"
                    item-value="value"

                    label="Flight Status"
                  />
                </v-col>
                <v-col
                  v-if="authUser.can.manageFlights"
                  class="d-flex align-center justify-center"
                  cols="12"
                  md="4"
                >
                  <v-select
                    v-model="flight.customs_status"
                    outlined
                    dense
                    hide-details
                    :items="[{name:'Accepted', value:'accepted'},{name:'Pending', value:'pending'}]"
                    item-text="name"
                    item-value="value"

                    clearable
                    label="Customs Status"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  order="last"
                >
                  <div class="d-flex align-center justify-center">
                    <v-checkbox
                      v-model="flight.ifr"
                      label="IFR"
                      @change="flight.wx = flight.ifr"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="flight.wx"
                      label="WX"
                    ></v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-tab-item>
        <!--        POBS-->
        <v-tab-item eager>
          <v-card-text>
            <v-form
              ref="pobForm"
              v-model="pobFormValid"
            >
              <v-row v-if="debug">
                {{ flight.pobs }} {{ flight.files }}
              </v-row>
              <v-row>
                <v-col
                  v-for="(person, person_index) in flight.pobs"
                  :key="person_index"
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-card
                    class="ma-2"
                    outlined
                  >
                    <v-card-text>
                      <v-select
                        v-model="person.crew"
                        :items="[{name:'CREW', value:true},{name:'PAX', value:false}]"
                        item-text="name"
                        item-value="value"
                        label="Status"
                      />

                      <v-text-field
                        v-model="person.lastname"
                        outlined
                        dense
                        :rules="[globalRules.required]"
                        label="Nachname"
                      />

                      <v-text-field
                        v-model="person.firstname"
                        outlined
                        dense
                        :rules="[globalRules.required]"
                        label="Vorname"
                      />

                      <v-text-field
                        v-model="person.birth"
                        v-mask="'##.##.####'"
                        outlined
                        dense

                        :rules="[globalRules.validDate, globalRules.dateNowOrEarlier, globalRules.required]"
                        label="Geburtsdatum"
                      />

                      <v-text-field
                        v-model="person.nationality"
                        outlined
                        dense
                        :rules="[globalRules.required]"
                        label="Nationalität"
                      />

                      <v-text-field
                        v-model="person.document_id"
                        outlined
                        dense
                        :rules="[globalRules.required]"
                        label="ReisepassNr"
                      />

                      <v-text-field
                        v-model="person.document_validity"
                        v-mask="'##.##.####'"
                        outlined
                        dense

                        :rules="[globalRules.dateNowOrLater, globalRules.validDate, globalRules.required ]"
                        :label="'Gültig bis ('+ 'DD.MM.YYYY' +')'"
                      />
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center ma-0 pa-0">
                      <v-btn
                        small
                        icon
                        color="error"
                        @click="REMOVE_PERSON_FROM_FLIGHT(person_index)"
                      >
                        <icon-with-tootip tooltip="Remove Person from Flight">
                          mdi-delete
                        </icon-with-tootip>
                      </v-btn>
                      <v-btn
                        v-if="!person.id"
                        icon
                        @click="handleSavePerson(person)"
                      >
                        <icon-with-tootip

                          tooltip="Save Person as Template"
                        >
                          mdi-content-save-edit-outline
                        </icon-with-tootip>
                      </v-btn>
                      <v-btn
                        v-if="person.type === 'person'"
                        icon
                        @click="deletePerson(person)"
                      >
                        <icon-with-tootip tooltip="Remove Person From Templates">
                          mdi-content-save-off-outline
                        </icon-with-tootip>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex d-block"
                >
                  <persons-on-board-selector></persons-on-board-selector>
                </v-col>
                <v-col>
                  <persons-on-board-table
                    v-if="flight.pobs && flight.pobs.length && false"
                    :pobs="flight.pobs"
                  ></persons-on-board-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <div v-if="flight.file">
                    <v-btn
                      icon
                      :href="flight.file.download.url"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-folder</v-icon>
                    </v-btn>
                    {{ flight.file.filename_disk }}
                    <v-alert color="error">
                      Files will only be linked after saving the Flight, otherwise Files are only in Your Library
                    </v-alert>
                  </div>
                  <file-upload-via-server @success="ADD_FILE_TO_FLIGHT" />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-tab-item>
        <!--        Information-->
        <v-tab-item eager>
          <v-card-text>
            <v-row>
              <v-col
                v-if="authUser.can.manageFlights"
                cols="12"
                md="4"
              >
                <user-info-card
                  v-if="flight.user"
                  :user="flight.user"
                />
                <company-select
                  v-model="flight.company_id"
                ></company-select>
              </v-col>
              <v-col
                cols="12"
                class="d-flex flex-column "
              >
                <span>Flight ID: {{ flight.id || 'pending...' }}</span>
                <span v-if="flight.is_ppr">Flight was submitted as PPR</span>
                <span>Created: {{ flight.created_at || 'pending ...' }}</span>
                <span v-if="flight.updated_at">Last Change: {{ flight.updated_at }}</span>
                <span>Accepted at: {{ flight.accepted_at || 'pending' }}</span>
                <span>Accepted by: {{ flight.accepted_by || 'pending' }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <!--        Log-->
        <v-tab-item
          v-if="dialog.action === 'edit'"
          eager
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h1>Log</h1>
                <div
                  v-for="activity in flight.activities"
                  :key="activity.id"
                >
                  {{
                    (activity.causer ? activity.causer.name : '') + ' ' + activity.description + ' at: ' + activity.created_at
                  }} <br>
                  <v-row>
                    <v-col cols="6">
                      {{ activity.properties.old }}
                    </v-col>
                    <v-col cols="6">
                      {{ activity.properties ? activity.properties.attributes : '' }}
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <!--        Handling-->
        <v-tab-item eager>
          <v-card-text>
            <v-form
              ref="contactForm"
              v-model="contactFormValid"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.contact_name"
                    outlined
                    dense
                    hide-details="auto"
                    label="Contact Person Name"
                    :rules="[globalRules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.contact_email"
                    outlined
                    dense
                    hide-details="auto"
                    label="E-Mail"
                    :rules="[globalRules.validEmail, globalRules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="flight.contact_phone"
                    outlined
                    dense
                    type="string"
                    hide-details="auto"

                    label="Phone"
                    :rules="[globalRules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-switch
                    v-model="flight.hangar"
                    hide-details="auto"
                    label="Hangar"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-switch
                    v-model="flight.stairs"
                    hide-details="auto"
                    label="Stairs"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-switch
                    v-model="flight.lifter"
                    label="Lifter"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-switch
                    v-model="flight.gpu"
                    label="GPU"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-switch
                    v-model="flight.refueling"
                    label="Refueling"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-switch
                    v-model="flight.rental_car"
                    label="Rental Car"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-switch
                    v-model="flight.car_access"
                    label="Car Access"
                  />
                </v-col>
                <v-btn
                  v-if="authUser.can.manageFlights && contactFormValid && dialog.action === 'edit'"
                  block
                  @click="generateCustomsMail(flight)"
                >
                  Zoll Mail
                  Generieren
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-tab-item>
        <!--        Errors-->
        <v-tab-item
          v-if="errors"
          eager
        >
          <v-card-text>
            <validation-errors-display></validation-errors-display>
          </v-card-text>
        </v-tab-item>
        <v-tab-item
          v-if="authUser.can.manageFlights"
          eager
        >
          <v-card-text>
            <h2>Flight Managers</h2>
            <v-textarea
              v-model="flight.fbo_remarks"
              outlined
              label="FBO Remarks"
              persistent-hint
              hint="Internal Remarks only readable by FlightManagers"
            ></v-textarea>
            <v-textarea
              v-model="flight.fbo_message"
              outlined
              label="FBO Message"
              persistent-hint
              hint="This Message is displayed on every Email Transaction"
            ></v-textarea>
            <v-select
              v-model="flight.assigned_users"
              outlined
              multiple
              small-chips
              deletable-chips
              :items="fbo_users"
              item-text="name"
              item-value="id"
              label="FBO Duty"
            >
            </v-select>
            <v-btn
              v-if="flight.user_on_duty"
              @click="saveDutyAssignement(flight.id, flight.user_on_duty)"
            >
              Generate Duty for User
            </v-btn>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions class="card-actions">
        <base-save-button
          :disabled="!valid"
          @click="saveFlight"
        />
        <base-delete-button
          v-if="dialog.action === 'edit'"
          @click="deleteFlight"
        />
        <base-save-button
          v-if="!valid"
          @click="validateForm()"
        >
          Check
        </base-save-button>
        <base-cancel-button @click="dialog.close()" />
        <v-btn
          color="success"
          text
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AirportTextField from '@/components/base/AirportTextField'
import { needsCustomsDeclaration, transformFlightToUTC } from 'mcritfunctionlibrary'
import moment from 'moment'
import { frontEndFormats } from '@/config'
import { getStatusColor } from '@/service/flightService'
import UserInfoCard from '@/components/User/UserInfoCard'
import emitter from '@/service/emitter'
import ValidationErrorsDisplay from '@/components/base/ValidationErrorsDisplay'
import FileUploadViaServer from '@/components/FileUpload/FileUploadViaServer'
import IconWithTootip from '@/components/base/IconWithTootip'
import { transformPersonToBackendFormat } from '@/service/personsService'
import PersonsOnBoardTable from '@/components/Flight/PersonsOnBoardTable'
import PersonsOnBoardSelector from '@/components/Flight/FlightDialog/PersonsOnBoardSelector'
import CompanySelect from '@/components/base/CompanySelect'
import axios from 'axios'

export default {
  name: 'GlobalFlightDialog',
  components: {
    CompanySelect,
    PersonsOnBoardSelector,
    PersonsOnBoardTable,
    IconWithTootip,
    FileUploadViaServer,

    ValidationErrorsDisplay,
    UserInfoCard,
    AirportTextField,
  },
  data() {
    return {
      debug: false,
      dialog: this.$flightDialog,
      flightFormValid: true,
      pobFormValid: true,
      contactFormValid: true,
    }
  },
  created() {
    if (this.authUser.can.manageFlights) {
      this.$store.dispatch('users/fetchFBOUsers')
    }
  },
  computed: {
    airportApprovalItems() {
      const items = [{ name: 'Accepted', value: 'accepted' }, { name: 'Pending', value: 'pending' }]
      if (this.dialog.action === 'edit') {
        items.push({ name: 'Declined', value: 'declined' })
      }

      return items
    },
    flightStatusItems() {
      const items = [{ name: 'Active', value: 'active' }, { name: 'Pending', value: 'pending' }]
      if (this.dialog.action === 'edit') {
        items.push({ name: 'Cancelled', value: 'cancelled' })
      }

      return items
    },
    valid() {
      return this.flightFormValid && this.pobFormValid && this.contactFormValid
    },
    ...mapGetters(
      {
        fbo_users: 'users/getUsers',
        flight: 'flight/getFlight',
        flightStatus: 'flight/getFlightStatus',
        timeZone: 'schedule/timeZone',
        errors: 'validation/getErrors',
        getFiles: 'files/getFiles',
      },
    ),
    dateInputRule() {
      return (this.flight.dep_date || this.flight.arr_date) ? true : 'At least one Date input is required'
    },
    airportInputRule() {
      return (this.flight.ad_dep?.toUpperCase() === 'ETSI' || this.flight.ad_dest.toUpperCase() === 'ETSI') ? true : 'ETSI has to be Part of the Flight'
    },
    flightNeedsCustomsDeclaration() {
      return !!(needsCustomsDeclaration(this.flight.ad_dest) || needsCustomsDeclaration(this.flight.ad_dep))
    },
  },
  methods: {
    saveDutyAssignement(flightID, userID) {
      axios.post('/api/duties/flights', { flight_id: flightID, user_id: userID }).then(res => {
        this.$snackbar.success(`Flight: ${flightID} will be assigned to User: ${userID}`)
      })
    },

    deletePerson(person) {
      this.$store.dispatch('person/deletePerson', person).then(() => {
        this.$snackbar.success('Person removed from Templates')
      })
    },
    handleSavePerson(person) {
      this.$store.dispatch('person/storePerson', transformPersonToBackendFormat(person)).then(() => {
        this.$snackbar.success('Person saved')
      })
    },
    handleFileUploadSuccess() {
      this.$dialog.alert('The File Upload was successfull, but is not linked to your Flight until you save the changes')
    },
    getStatusColor,
    swapRoute() {
      const temp = { ...this.flight }
      this.flight.ad_dest = temp.ad_dep
      this.flight.arr_date = temp.dep_date
      this.flight.arr_time = temp.dep_time

      this.flight.ad_dep = temp.ad_dest
      this.flight.dep_date = temp.arr_date
      this.flight.dep_time = temp.arr_time
    },
    toggleDebug() {
      this.debug = !this.debug
    },
    generateCustomsMail(flight) {
      this.$store.dispatch('flight/updateFlight', flight).then(() => {
        this.$store.dispatch('flight/generateCustomsMail', flight).then(() => {
          this.$snackbar.success('Mail wurde geschickt')
        })
      })
    },
    handleDateKeyInput(event, field) {
      const dateObject = moment(this.flight[field], frontEndFormats.DATE_FORMAT)
      const isValidDate = dateObject.isValid()
      if (event.key === '+') {
        this.flight[field] = isValidDate ? dateObject.add(1, 'day').format(frontEndFormats.DATE_FORMAT) : moment().format(frontEndFormats.DATE_FORMAT)
      }
      if (event.key === '-') {
        this.flight[field] = isValidDate ? dateObject.subtract(1, 'day').format(frontEndFormats.DATE_FORMAT) : moment().format(frontEndFormats.DATE_FORMAT)
      }
    },
    handleTimeKeyInput(event, field) {
      const dateObject = moment(this.flight[field], frontEndFormats.TIME_FORMAT)
      const isValidDate = dateObject.isValid()
      if (event.key === '+') {
        this.flight[field] = isValidDate ? dateObject.add(1, 'hours').format(frontEndFormats.TIME_FORMAT) : moment().format(frontEndFormats.TIME_FORMAT)
      }
      if (event.key === '-') {
        this.flight[field] = isValidDate ? dateObject.subtract(1, 'hours').format(frontEndFormats.TIME_FORMAT) : moment().format(frontEndFormats.TIME_FORMAT)
      }
    },
    ...mapMutations(
      {
        REMOVE_PERSON_FROM_FLIGHT: 'flight/REMOVE_PERSON_FROM_FLIGHT',
        ADD_PERSON_TO_FLIGHT: 'flight/ADD_PERSON_TO_FLIGHT',
        ADD_FILE_TO_FLIGHT: 'flight/ADD_FILE_TO_FLIGHT',
      },
    ),
    ...mapActions({
      fetchUserFiles: 'files/fetchUserFiles',
    }),
    validateForm() {
      this.$refs.flightForm.validate()
      this.$refs.pobForm.validate()
      this.$refs.contactForm.validate()
    },
    resetForm() {
      if (this.dialog.action === 'edit') {
        this.$store.dispatch('flight/fetchFlightById', this.flight.id)
      }
      this.$store.commit('flight/CLEAR_FLIGHT')
    },
    saveFlight() {
      this.validateForm()
      const flight = transformFlightToUTC(this.flight, this.timeZone)
      this.loading = true
      if (this.dialog.action === 'edit') {
        this.$store.dispatch('flight/updateFlight', flight)
          .then(() => {
            this.dialog.close()
            emitter.emit('flightsChanged')
          })
          .catch(err => {
            this.$snackbar.error('Could not save Flight')
            console.log(err)
          })
      } else if (this.dialog.action === 'create' && this.dialog.target === 'FlightController') {
        this.$store.dispatch('flight/storeFlight', flight)
          .then(res => {
            this.dialog.close()
            emitter.emit('flightsChanged')
          })
          .catch(err => {
            this.$snackbar.error('Could not save Flight')
            console.log(err)
          })
      } else if (this.dialog.action === 'create' && this.dialog.target === 'UserFlightController') {
        this.$store.dispatch('flight/storeUserFlight', { user: this.dialog.user, flight })
          .then(res => {
            this.dialog.close()
            emitter.emit('flightsChanged')
          })
          .catch(err => {
            this.$snackbar.error('Could not save Flight')
            console.log(err)
          })
      } else if (this.dialog.action === 'create' && this.dialog.target === 'CompanyFlightController') {
        this.$store.dispatch('flight/storeCompanyFlight', { company: this.dialog.company, flight })
          .then(res => {
            this.dialog.close()
            emitter.emit('flightsChanged')
          })
          .catch(err => {
            this.$snackbar.error('Could not save Flight')
            console.log(err)
          })
      }
      this.loading = false
    },
    deleteFlight() {
      this.$dialog.requestConfirmation('Delete Flight ?').then(() => {
        this.$store.dispatch('flight/deleteFlight', this.flight).then(() => {
          emitter.emit('flightsChanged')
          this.dialog.close()
        })
      })
    },
  },
}
</script>

<style scoped>
.card-outter {
  padding-bottom: 50px;
}

.card-actions {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
