import Vue from 'vue'
import PPRDialog from './pprDialog'

const Plugin = {
  install(Vue, options) {
    Vue.prototype.$pprDialog = PPRDialog
  },
}

Vue.use(Plugin)
