<template>
  <v-btn
    v-if="updateExists"
    color="error"
    class="shiny"
    rel="nofollow"
    @mouseenter="text = 'LOAD UPDATE'"
    @mouseleave="text = 'Update Available'"
    @click="refreshApp"
  >
    {{ text }}
  </v-btn>
</template>

<script>

import update from '@/mixins/update'

export default {
  name: 'TheUpdateButton',
  mixins: [update],
  data() {
    return {
      text: 'Update Available',
    }
  },
}
</script>

<style lang="scss" scoped>
.shiny {
  width: 30%;
  z-index: 4;
  bottom: 5%;
  right: 40px;
  position: fixed;

  .v-application &.v-btn.v-btn--is-elevated {
    box-shadow: 0 1px 20px 1px var(--v-error-base) !important;

    &:hover {
      box-shadow: none !important;
    }
  }
}
</style>
