<template>
  <v-combobox
    ref="field"
    outlined
    dense
    :search-input.sync="search"
    :loading="loading"
    v-bind="$attrs"
    :value="model"
    :items="items"
    item-text="ident"
    item-value="ident"
    :return-object="false"
    :filter="filterObject"
    persistent-hint
    :prepend-icon="swapping ? 'mdi-swap-vertical' : null"
    :hint="getAirportString"
    @click:prepend="$emit('swap')"
    v-on="$listeners"
    @update:search-input="updateTargetInput"
  >
    <template v-slot:item="data">
      <v-list-item-avatar>
        <v-icon>mdi-airport</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="data.item.ident"></v-list-item-title>
        <v-list-item-subtitle v-text="data.item.name"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AirportTextField',
  props: {
    value: {
      type: String,
      default: '',
    },
    swapping: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTargetInput: null,
      search: null,
      items: [],
      loading: false,
    }
  },
  computed: {
    getAirportString() {
      const airport = this.items.find(airport => airport.ident?.toUpperCase() === this.value?.toUpperCase())

      return airport ? `${airport.name}(${airport.iso_country})` : 'Unknown Airport'
    },

    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('errors', this.$refs.field.validate())
      },
    },
  },
  watch: {
    search: {
      immediate: false,
      handler(newVal, oldVal) {
        if (newVal && newVal.length >= 2) {
          this.fetchDebounced(newVal)
        }
      },
    },
  },
  methods: {
    updateTargetInput(currentValue) {
      this.model = currentValue
    },
    fetchAirports(searchString) {
      this.loading = true
      axios.get(`/api/airports?search=${searchString}`).then(res => {
        this.items = res.data.data
      }).catch(() => null).finally(() => (this.loading = false))
    },
    fetchDebounced(searchString) {
      clearTimeout(this._timerId)
      this._timerId = setTimeout(() => {
        this.fetchAirports(searchString)
      }, 200)
    },
    filterObject(item, queryText, itemText) {
      return (
        item.ident.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        || item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
  },
}
</script>

<style scoped>

</style>
