<template>
  <v-btn
    v-bind="$attrs"
    icon
    small
    @click="reload"
  >
    <icon-with-tootip tooltip="Reload">
      mdi-sync
    </icon-with-tootip>
  </v-btn>
</template>

<script>
import emitter from '@/service/emitter'
import IconWithTootip from '@/components/base/IconWithTootip'

export default {
  name: 'TheReloadButton',
  components: { IconWithTootip },
  methods: {
    reload() {
      emitter.emit('reload')
      this.$store.dispatch('auth/fetchUser').then(() => {
      })
    },
  },
}
</script>

<style scoped>

</style>
