import moment from 'moment-timezone'
import { getHolidayOfDate, getSunTimesOfDate } from '@/service/holidayService'

const presetTimezone = JSON.parse(localStorage.getItem('timeZone')) ?? 'UTC'

export default {
  namespaced: true,

  state: {
    withTrashed: false,
    allowedTimeZones: ['UTC', 'Europe/Berlin'],
    timeZone: presetTimezone,
    scheduleDateString: moment().format('YYYY-MM-DD'),
    mCritEnabled: JSON.parse(localStorage.getItem('mCritEnabled')) ?? true,
    showTrafficVolume: JSON.parse(localStorage.getItem('showTrafficVolume')) ?? true,
    showWeekly: JSON.parse(localStorage.getItem('showWeekly')) ?? true,
    showStatistic: JSON.parse(localStorage.getItem('showStatistic')) ?? true,
    showDuty: JSON.parse(localStorage.getItem('showDuty')) ?? true,
  },

  getters: {
    allowedTimeZones: state => state.allowedTimeZones,
    timeZone: state => state.timeZone,
    timeZoneOffset: state => moment.tz(state.scheduleDateString, 'YYYY-MM-DD', state.timeZone).utcOffset(),
    scheduleDateString: state => state.scheduleDateString,
    scheduleDateObject: state => moment.tz(state.scheduleDateString, 'YYYY-MM-DD', state.timeZone),
    scheduleDateHoliday: state => getHolidayOfDate(state.scheduleDateString),
    scheduleDateSunTimes: state => getSunTimesOfDate(state.scheduleDateString, state.timeZone),
    getDateDayName: state => moment.utc(state.scheduleDateString, 'YYYY-MM-DD').format('dddd'),
    showMCrit: state => state.mCritEnabled,
    showDuty: state => state.showDuty,
    showWeekly: state => state.showWeekly,
    showStatistic: state => state.showStatistic,
    showTrafficVolume: state => state.showTrafficVolume,
  },

  actions: {

  },

  mutations: {
    TOGGLE_M_CRIT(state) {
      state.mCritEnabled = !state.mCritEnabled
      localStorage.setItem('mCritEnabled', JSON.stringify(state.mCritEnabled))
    },
    TOGGLE_DUTY_DISPLAY(state) {
      state.showDuty = !state.showDuty
      localStorage.setItem('showDuty', JSON.stringify(state.showDuty))
    },
    TOGGLE_WEEKLY_DISPLAY(state) {
      state.showWeekly = !state.showWeekly
      localStorage.setItem('showWeekly', JSON.stringify(state.showWeekly))
    },
    TOGGLE_STATISTIC_DISPLAY(state) {
      state.showStatistic = !state.showStatistic
      localStorage.setItem('showStatistic', JSON.stringify(state.showStatistic))
    },
    SET_TZ(state, timeZone) {
      state.timeZone = timeZone
      localStorage.setItem('timeZone', JSON.stringify(state.timeZone))
    },
    TOGGLE_TRAFFIC_VOLUME(state) {
      state.showTrafficVolume = !state.showTrafficVolume
      localStorage.setItem('showTrafficVolume', JSON.stringify(state.showTrafficVolume))
    },
    SET_SCHEDULE_DATE(state, dateString) {
      state.scheduleDateString = moment.tz(dateString, 'YYYY-MM-DD', state.timeZone).tz(state.timeZone).format('YYYY-MM-DD')
    },
    CHANGE_DAY(state, direction) {
      if (direction === 'today') {
        state.scheduleDateString = moment().tz(state.timeZone).format('YYYY-MM-DD')
      } else {
        state.scheduleDateString = moment.tz(state.scheduleDateString, 'YYYY-MM-DD', state.timeZone).tz(state.timeZone).startOf('day').add(direction, 'd')
          .format('YYYY-MM-DD')
      }
    },
  },
}
