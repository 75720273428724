import Vue from 'vue'
import Snackbar from './snackbar'

const Plugin = {
  install(Vue, options) {
    Vue.prototype.$snackbar = Snackbar
  },
}

Vue.use(Plugin)
