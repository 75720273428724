import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import snackbar from '@/plugins/snackbar/snackbar'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

if (localStorage.getItem('token')) {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
}

// RESPONSE INTERCEPTORS
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 || error.response.data.message === '401 Unauthorized') {
      console.log('Intercept: 401 - Unauthenticated ...')
      if (store.getters['auth/check']) {
        console.log('... but user identified')
        store.commit('auth/LOGOUT')
      }
      router.replace({ name: 'Login' })
    }
    if (error.response.status === 422 || error.response.data.message === '422 Unprocessable Entity') {
      console.log('Intercept: 422 - Validation Errors Detected')
      store.commit('validation/SET_VALIDATION_ERRORS', error.response.data.errors)
    }
    if (error.response.status === 503) {
      console.log('Intercept: 503 - Maintenance')

      return router.push({ name: 'maintenance' })
    }
    if (error.response.status === 403) {
      console.log('Intercept: 403 - Unauthorized')
      if (error.response.data.message) {
        snackbar.error(error.response.data.message)
      }
    }
    if (error.response.status === 429) {
      console.log('Intercept: 429 - To Many Requests')
      alert('You made to many requests per Minute, please wait for 2 Minutes')
    }

    return Promise.reject(error)
  },
)

// REQUEST INTERCEPTORS
axios.interceptors.request.use(
  config => {
    store.commit('validation/SET_VALIDATION_ERRORS', null)

    return config
  },
  error =>

    // Do something with request error
    Promise.reject(error),

)

// Add a response interceptor
axios.interceptors.response.use(
  response =>

    // Do something with response data
    response,
  error =>

    // Do something with response error
    Promise.reject(error),

)

Vue.prototype.axios = axios
