<template>
  <v-app>
    <div class="boxed-container">
      <div class="d-flex align-center mt-14 py-4">
        <div class="d-flex d-block flex-wrap align-center d-inline-block py-2">
          <div
            class="pl-4 py-4 d-flex d-block justify-center align-center"
            style="background-color: #242732"
          >
            <v-img
              :src="require('@/assets/ima_logo.png')"
              width="50px"
              height="50px"
              alt="logo"
              contain
              eager
              class="app-logo me-3"
            ></v-img>

            <a
              href="https://etsi-ima.de"
              class="heroHeading px-2 text-decoration-none font-weight-thin"
            >
              Ingolstadt Manching Airport (ETSI)
            </a>
          </div>
        </div>
        <div class="d-flex align-center font-weight-black">
          <router-link
            v-if="!isLoggedIn"
            style="text-decoration: none; color: inherit;"
            text
            :to="{name: 'Login'}"
            class="ml-4"
          >
            LOGIN
          </router-link>
          <router-link
            v-if="isLoggedIn"
            style="text-decoration: none; color: inherit;"
            text
            to="/"
            class="mx-2 text-uppercase font-weight-thin"
          >
            SCHEDULE
          </router-link>
          <base-time-zone-selector
            class="mx-2"
            text
          ></base-time-zone-selector>
        </div>
      </div>
      <v-main>
        <v-container class="fill-height">
          <slot />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>

import BaseTimeZoneSelector from '@/components/base/BaseTimeZoneSelector'

export default {
  components: { BaseTimeZoneSelector },
  created() {
    this.$vuetify.theme.dark = true
  },
}
</script>

<style lang="scss" scoped>

.boxed-container {
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
}
.heroHeading{
  color: white !important;
  font-size: 28px;
  letter-spacing: 2px;
  text-align: left;
  line-height: 1;
  text-decoration: none;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-property: all;
  transition-delay: 0.1s;
}

.primaryHover {
  background-color: #242732;
}

.primaryHover:hover {
  background-color: var(--v-primary-base);
}

</style>
