<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="drawer"></vertical-nav-menu>
    <v-app-bar
      absolute
      app
      color="transparent"
      flat
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6 flex-wrap">
          <!-- Left Content -->
          <v-btn
            icon
            @click.stop="drawer = !drawer"
          >
            <v-icon>{{ drawer ? 'mdi-backburger' : 'mdi-menu' }}</v-icon>
          </v-btn>
          <div class="hidden-sm-and-down">
            {{ authUser.name }}
          </div>
          <v-spacer></v-spacer>

          <!-- Right Content -->
          <theme-switcher/>
          <base-time-zone-selector/>
          <the-reload-button/>
          <v-btn
            icon
            @click="refreshPage"
          >
            <icon-with-tootip
              tooltip="Check for Updates"
            >
              mdi-download-outline
            </icon-with-tootip>
          </v-btn>
          <v-btn
            class="ms-3 d-none"
            icon
            small
          >
            <icon-with-tootip tooltip="Notifications">
              mdi-bell-outline
            </icon-with-tootip>
          </v-btn>
          <the-logout-button
            class="ms-3"
            icon
            small
          ></the-logout-button>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <base-dialog></base-dialog>
        <global-flight-dialog></global-flight-dialog>
        <base-snackbar></base-snackbar>
        <validation-errors-display/>
        <v-alert
          v-if="notificationsAllowed !== 'granted'"
          dismissible
          prominent
          type="error"
        >
          <v-col class="grow">
            Please allow Notifications
          </v-col>
          <v-col class="shrink">
            <v-btn
              dark
              @click="requestNotificationPermission"
            >
              Benachrichtigungen zulassen
            </v-btn>
          </v-col>
        </v-alert>
        <!--        Is loaded as component in App vue, otherwise use router-view-->
        <slot/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import TheLogoutButton from '@/components/base/TheLogoutButton'
import {mapGetters} from 'vuex'
import BaseDialog from '@/components/base/BaseDialog'
import ValidationErrorsDisplay from '@/components/base/ValidationErrorsDisplay'
import GlobalFlightDialog from '@/components/Flight/GlobalFlightDialog'
import BaseSnackbar from '@/components/base/BaseSnackbar'
import emitter from '@/service/emitter'
import BaseTimeZoneSelector from '@/components/base/BaseTimeZoneSelector'
import ThemeSwitcher from '@/components/Controlls/ThemeSwitcher'
import VerticalNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu'
import TheReloadButton from '@/components/TheReloadButton'
import IconWithTootip from '@/components/base/IconWithTootip'

export default {
  components: {
    IconWithTootip,
    TheReloadButton,
    VerticalNavMenu,
    ThemeSwitcher,
    BaseTimeZoneSelector,
    BaseSnackbar,
    GlobalFlightDialog,
    ValidationErrorsDisplay,
    BaseDialog,
    TheLogoutButton,
  },
  data() {
    return {
      notificationsAllowed: 'granted',
      drawer: false,
      fixed: false,
    }
  },
  computed: {
    ...mapGetters({
      timeZone: 'schedule/timeZone',
    }),
  },

  created() {
    document.title = 'IMA Interface'
    if (('Notification' in window) && location.protocol === 'https') {
      this.notificationsAllowed = Notification.permission
    }
  },
  mounted() {
    this.subscribe()
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    refreshPage() {
      console.log('Reload')
      window.location.reload()
    },
    requestNotificationPermission() {
      Notification.requestPermission().then(result => {
        this.notificationsAllowed = result
      })
    },
    notifyMe(text) {
      // Let's check if the browser supports notifications
      if (!('Notification' in window)) {
        console.log('This browser does not support desktop notification')
      } else if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        // eslint-disable-next-line no-unused-vars
        const notification = new Notification(text)
      } else if (Notification.permission !== 'denied' || Notification.permission === 'default') {
        Notification.requestPermission().then(permission => {
          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            // eslint-disable-next-line no-unused-vars
            const notification = new Notification(text)
          }
        })
      }
      this.$dialog.alert(text)
    },
    subscribe() {
      console.log('Attempt to subscribe...')

      // Channels
      this.pusher.subscribe('private-auth')
      this.pusher.subscribe('private-ima')

      // Events
      this.pusher.bind('flight.updated', data => this.handleFlightUpdate(data))
      this.pusher.bind('ppr.created', data => {
        emitter.emit('ppr.created')
        this.notifyMe('Neuer PPR Request')
      })
    },
    unsubscribe() {
      console.log('Unsubscribing ...')
      this.pusher.unbind('ppr.created')
      this.pusher.unbind('flight.updated')
      this.pusher.unsubscribe('private-auth')
      this.pusher.unsubscribe('private-ima')
    },
    handleFlightUpdate(data) {
      const index = this.$store.getters['flights/getFlights'].findIndex(item => item.id === data.id)
      if (index >= 0) {
        this.$store.dispatch('flight/reloadAndReplaceFlightByIdSilently', data.id)
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;
  }
}

.boxed-container {
  //max-width: 1440px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>
