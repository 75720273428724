<template>
  <v-snackbar
    v-model="snackbar.showBool"
    app
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    centered
    rounded
    transition="fade-transition"
  >
    <div class="d-flex d-block justify-space-between">
      <div class="grow text-center">
        {{ snackbar.text }}
      </div>
      <div class="shrink">
        <v-btn
          small
          icon
          @click="snackbar.close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'BaseSnackbar',

  data() {
    return {
      snackbar: this.$snackbar,
    }
  },

}
</script>

<style scoped>
</style>
