import store from '../store'

export default function ({ next }) {
  console.log('Checking Authentication...')
  if (!store.getters['auth/check']) {
    console.log('Auth Check Failed!')
    next({ name: 'Login' })
  } else {
    console.log('User is authenticated!')
    next()
  }
}
