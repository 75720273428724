export function getStatusColor(status) {
  switch (status) {
    case 'active':
      return 'success'
    case 'pending':
      return 'warning'
    case 'declined':
      return 'error'
    case 'cancelled':
      return 'error'
    default:
      return 'grey'
  }
}
