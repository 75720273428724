class Dialog {
  constructor() {
    this.showBool = false
    this.text = ''
    this.title = ''
    this.reject = null
    this.resolve = null
    this.type = 'alert'
    this.message = null
    this.options = []
    this.choice = null
    this.resolveText = 'Affirm'
    this.rejectText = 'Cancel'
  }

  _show() {
    this.showBool = true

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  requestChoice(text, resolveText, options) {
    this.type = 'choice'
    this.text = text
    this.options = options
    this.choice = options[0].value
    this.title = 'Make a choice'
    this.resolveText = resolveText ?? 'OK'

    return this._show()
  }

  alert(text) {
    this.type = 'alert'
    this.text = text
    this.title = 'Achtung'

    return this._show()
  }

  requestConfirmation(text) {
    this.type = 'confirm'
    this.text = text
    this.title = 'Please Confirm'

    return this._show()
  }

  prompt(text) {
    this.type = 'prompt'
    this.text = text
    this.title = 'Please Confirm'
    this.message = null

    return this._show()
  }

  decide(text, resolveText, rejectText) {
    this.type = 'decide'
    this.text = text
    this.title = 'Please Chose'
    this.choice = null
    this.resolveText = resolveText ?? 'YES'
    this.rejectText = rejectText ?? 'NO'

    return this._show()
  }

  cancel() {
    this.showBool = false

    // this.reject()
  }

  confirm(response) {
    this.showBool = false
    this.resolve(response)
  }
}

export default new Dialog()
