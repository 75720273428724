<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Crew
          </th>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Birth
          </th>
          <th class="text-left">
            Nationality
          </th>
          <th class="text-left">
            Document ID
          </th>
          <th class="text-left">
            Valid until
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(person, i) in pobs"
          :key="i"
        >
          <td>
            <v-icon
              small
              :color="person.crew ? 'success' : 'error'"
            >
              mdi-checkbox-blank-circle
            </v-icon>
          </td>
          <td>{{ person.firstname + ' ' + person.lastname }}</td>
          <td>{{ person.birth }}</td>
          <td>{{ person.nationality }}</td>
          <td>{{ person.document_id }}</td>
          <td>{{ person.document_validity }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'PersonsOnBoardTable',
  props: {
    pobs: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
