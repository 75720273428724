import { render, staticRenderFns } from "./TheReloadButton.vue?vue&type=template&id=0a9bb0aa&scoped=true"
import script from "./TheReloadButton.vue?vue&type=script&lang=js"
export * from "./TheReloadButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a9bb0aa",
  null
  
)

export default component.exports