import { getHolidayByDate } from 'feiertagejs'
import sunCalc from 'suncalc'
import moment from 'moment-timezone'
import { frontEndFormats } from '@/config'

export function getHolidayOfDate(dateString) {
  const dateObject = moment.utc(dateString, 'YYYY-MM-DD')
  const holiday = getHolidayByDate(dateObject.toDate(), 'BY')
  if (holiday) {
    return holiday.translate('de')
  }

  return null
}

export function getSunTimesOfDate(dateString, tz = 'UTC', returnObject = false) {
  const dateObject = moment.utc(dateString, 'YYYY-MM-DD')
  const sunTimes = sunCalc.getTimes(dateObject, 48.70944, 11.53694)
  if (returnObject) {
    return {
      DAWN: moment(sunTimes.dawn),
      SR: moment(sunTimes.sunrise),
      SS: moment(sunTimes.sunsetStart),
      DUSK: moment(sunTimes.dusk),
    }
  }

  return {
    DAWN: moment(sunTimes.dawn).tz(tz).format(`${frontEndFormats.TIME_FORMAT} zz`),
    SR: moment(sunTimes.sunrise).tz(tz).format(`${frontEndFormats.TIME_FORMAT} zz`),
    SS: moment(sunTimes.sunsetStart).tz(tz).format(`${frontEndFormats.TIME_FORMAT} zz`),
    DUSK: moment(sunTimes.dusk).tz(tz).format(`${frontEndFormats.TIME_FORMAT} zz`),
  }
}
