require('./overrides.scss')

export default {
  theme: {
    dark: JSON.parse(localStorage.getItem('darkMode')) || false,
    themes: {
      light: {
        primary: '#007bc1',
        accent: '#9155FD',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
      dark: {
        primary: '#007bc1',
        accent: '#9155FD',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
    },
  },
}
