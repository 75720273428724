import { clone } from 'lodash'
import moment from 'moment-timezone'
import { frontEndFormats } from '@/config'

export function transformPersonToFrontendFormat(person) {
  const formattedPerson = clone(person)
  formattedPerson.birth = formattedPerson.birth ? moment(person.birth).format(frontEndFormats.DATE_FORMAT) : null
  formattedPerson.document_validity = formattedPerson.document_validity ? moment(person.document_validity).format(frontEndFormats.DATE_FORMAT) : null

  return formattedPerson
}

export function transformPersonToBackendFormat(person) {
  console.log('POB to BackEND')
  const formattedPerson = clone(person)
  formattedPerson.birth = moment.utc(person.birth, 'DD.MM.YYYY').toISOString()
  formattedPerson.document_validity = moment.utc(person.document_validity, 'DD.MM.YYYY').toISOString()

  return formattedPerson
}
