import axios from 'axios'

export default {

  namespaced: true,

  state: {
    flight: null,
    flights: [],
    status: '',
  },

  getters: {
    getFlights: state => state.flights,
    getFlightsStatus: state => state.status,
  },

  actions: {
    fetchFlights({ commit, state }) {
      commit('SET_FLIGHTS_STATUS', 'loading')
      commit('CLEAR_FLIGHTS')

      return new Promise((resolve, reject) => {
        axios.get('api/privateWings/flights').then(res => {
          commit('SET_FLIGHTS', res.data.data)
          commit('SET_FLIGHTS_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_FLIGHTS_STATUS', 'error')
        })
      })
    },
    deleteFlight({ commit, state }, flight) {
      return new Promise((resolve, reject) => {
        axios.delete(`api/privateWings/flights/${flight.id}`).then(res => {
          // Remove the Flight from the Store as well
          commit('REMOVE_FLIGHT', flight)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    storeFlight({ commit, state }, flight) {
      commit('SET_FLIGHT_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.post('api/privateWings/flights', flight).then(res => {
          commit('SET_FLIGHT_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_FLIGHT_STATUS', 'error')
          reject(err)
        })
      })
    },
    updateFlight({ commit, state }, flight) {
      commit('SET_FLIGHT_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.patch(`api/privateWings/flights/${flight.id}`, flight).then(res => {
          commit('SET_FLIGHT_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_FLIGHT_STATUS', 'error')
          reject(err)
        })
      })
    },
  },

  mutations: {
    SET_FLIGHTS(state, flights) {
      state.flights = flights
    },
    SET_FLIGHTS_STATUS(state, status) {
      state.status = status
    },
    REMOVE_FLIGHT(state, flight) {
      const index = state.flights.findIndex(item => item.id === flight.id)
      if (index > 0) state.flights.splice(index, 1)
    },
  },
}
