<template>
  <v-btn
    text
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>Abbruch</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseCancelButton',
}
</script>

<style scoped>

</style>
