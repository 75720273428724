import axios from 'axios'

export default {

  namespaced: true,

  state: {
    companies: [],
    status: '',
  },

  getters: {
    getCompanies: state => state.companies,
    getCompaniesStatus: state => state.status,
  },

  actions: {
    fetchCompanies({ commit }) {
      commit('SET_COMPANIES_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get('api/companies').then(res => {
          commit('SET_COMPANIES', res.data.data)
          commit('SET_COMPANIES_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_COMPANIES_STATUS', 'error')
        })
      })
    },
  },

  mutations: {
    SET_COMPANIES(state, companies) {
      state.companies = companies
    },
    SET_COMPANIES_STATUS(state, status) {
      state.status = status
    },
    ADD_COMPANY_TO_COMPANIES(state, company) {
      state.companies.push(company)
    },
    UPDATE_COMPANY(state, company) {
      const index = state.companies.findIndex(item => item.id === company.id)
      if (index >= 0) state.companies.splice(index, 1, company)
    },
  },
}
