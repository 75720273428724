<template>
  <v-card
    :loading="loading"
    v-bind="$attrs"
  >
    <v-card-title>
      File Upload
    </v-card-title>
    <v-card-subtitle>
      *.doc,*.pdf,*.docx allowed
    </v-card-subtitle>
    <v-card-text>
      <v-file-input
        id="upload"
        v-model="formData.file"
        type="file"
        @change="handleFileChange"
      />
      <v-progress-linear
        v-if="loading"
        :value="progress"
      ></v-progress-linear>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!formData.file"
        @click="uploadFile"
      >
        Upload File
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FileUploadViaServer',
  data() {
    return {
      progress: 0,
      formData: {},
      uploadUrl: '',
      loading: false,
    }
  },
  methods: {
    async uploadFile() {
      try {
        const self = this
        const config = {
          onUploadProgress(progressEvent) {
            self.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            )
          },
        }

        const formData = new FormData()
        formData.append('file', this.formData.file)
        this.loading = true
        const res = await axios.post('/api/files', formData, config)
        this.$store.commit('files/ADD_FILE', res.data.data)
        this.$snackbar.success('File Upload Success')
        this.$emit('success', res.data.data)
        this.resetForm()
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$snackbar.error('File Upload Failed')
      }
    },
    resetForm() {
      this.progress = 0
      this.formData = {}
    },
    handleFileChange() {
      // this.resetForm()
    },
  },
}

</script>

<style scoped>

</style>
