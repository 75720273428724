<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template
      v-if="text"
      v-slot:activator="{ on, attrs }"
    >
      <span
        v-bind="attrs"
        class="text-uppercase font-weight-thin"
        v-on="on"
      >
        TZ: {{ timeZone }}
      </span>
    </template>
    <template
      v-else
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        text
        v-bind="attrs"
        v-on="on"
      >
        TZ: {{ timeZone }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Chose TimeZone
      </v-card-title>
      <v-card-text>
        <v-col
          v-for="(tz, i) in allowedTimeZones"
          :key="i"
          class="text-center align-center"
        >
          <v-btn @click="handleTimeZoneChange(tz)">
            {{ tz }}
          </v-btn>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import emitter from '@/service/emitter'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseTimeZoneSelector',
  props: {
    text: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      timeZone: 'schedule/timeZone',
      allowedTimeZones: 'schedule/allowedTimeZones',
    }),
  },
  methods: {
    handleTimeZoneChange(tz) {
      emitter.emit('tz.change', tz)
      this.$store.commit('schedule/SET_TZ', tz)
      this.dialog = false
    },
  },
}
</script>

<style scoped>

</style>
