import axios from 'axios'
import { cloneDeep } from 'lodash'

export default {

  namespaced: true,

  state: {
    file: null,
    status: '',
  },

  getters: {
    getFile: state => state.file,
    getFileStatus: state => state.status,
  },

  actions: {
    deleteFile({ commit, state }, file) {
      return new Promise((resolve, reject) => {
        axios.delete(`api/files/${file.id}`).then(res => {
          // Remove the Flight from the Store as well
          commit('files/REMOVE_FILE', file, { root: true })
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },

  mutations: {

    SET_FLIGHT(state, flight) {
      state.flight = cloneDeep(flight)
    },

    SET_FLIGHT_STATUS(state, status) {
      state.status = status
    },
  },
}
