import axios from 'axios'

export default {

  namespaced: true,

  state: {
    users: [],
    status: '',
  },

  getters: {
    getUsers: state => state.users,
    getUsersStatus: state => state.status,
  },

  actions: {
    fetchUsers({ state, commit }) {
      commit('SET_USERS_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get('api/users').then(res => {
          commit('SET_USERS', res.data.data)
          commit('SET_USERS_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_USERS_STATUS', 'error')
        })
      })
    },
    fetchFBOUsers({ state, commit }) {
      commit('SET_USERS_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get('api/fbo/users').then(res => {
          commit('SET_USERS', res.data.data)
          commit('SET_USERS_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_USERS_STATUS', 'error')
        })
      })
    },
  },

  mutations: {
    SET_USERS(state, users) {
      state.users = users
    },
    SET_USERS_STATUS(state, status) {
      state.status = status
    },
    UPDATE_USER(state, user) {
      const index = state.users.findIndex(item => item.id === user.id)
      if (index >= 0) state.users.splice(index, 1, user)
    },
  },
}
