<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="$vuetify.theme.dark"
      @click="toggleDarkMode"
    >
      {{ $vuetify.theme.dark ? 'mdi-weather-sunny' : 'mdi-weather-night' }}
    </v-icon>
  </v-fade-transition>
</template>

<script>

export default {
  name: 'ThemeSwitcher',
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('darkMode', JSON.stringify(this.$vuetify.theme.dark))
    },
  },
}

</script>

<style>
</style>
