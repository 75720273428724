<template>
  <v-btn
    color="primary"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>Speichern</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseSaveButton',
}
</script>

<style scoped>

</style>
