import axios from 'axios'

export default {

  namespaced: true,

  state: {
    occupation: null,
    occupationStatus: '',
  },

  getters: {
    getOccupation: state => state.occupation,
    getOccupationStatus: state => state.occupationStatus,
  },

  actions: {
    fetchOccupationByID({ commit, state }, occupationID) {
      commit('SET_OCCUPATION_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.get(`api/occupations/${occupationID}`).then(res => {
          commit('SET_OCCUPATION', res.data.data)
          commit('SET_OCCUPATION_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          reject(err)
          commit('SET_OCCUPATION_STATUS', 'error')
        })
      })
    },
    createOccupation({ commit, state }, occupation) {
      commit('SET_OCCUPATION_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.post('api/occupations', occupation).then(res => {
          commit('SET_OCCUPATION', res.data.data)
          commit('SET_OCCUPATION_STATUS', 'success')
          resolve(res)
        }).catch(err => {
          commit('SET_OCCUPATION_STATUS', 'error')
          reject(err)
        })
      })
    },
    saveOccupationViaCompany({ commit }, { companyID, occupation }) {
      axios.post(`/api/companies/${companyID}/occupations`, occupation).then(res => {
        commit('SET_OCCUPATION', res.data.data)
      }).catch(err => {
        console.log(err)
      })
    },
    updateOccupation({ commit, state }, occupation) {
      commit('SET_OCCUPATION_STATUS', 'loading')

      return new Promise((resolve, reject) => {
        axios.patch(`api/occupations/${occupation.id}`, occupation).then(res => {
          commit('SET_OCCUPATION', res.data.data)
          commit('company/UPDATE_OCCUPATION', res.data.data, { root: true })
          commit('SET_OCCUPATION_STATUS', 'success')

          // commit('occupations/UPDATE_OCCUPATION', res.data.data, { root: true })
          resolve(res)
        }).catch(err => {
          commit('SET_OCCUPATION_STATUS', 'error')
          reject(err)
        })
      })
    },
  },

  mutations: {
    SET_OCCUPATION(state, occupation) {
      state.occupation = occupation
    },
    SET_OCCUPATION_STATUS(state, status) {
      state.occupationStatus = status
    },
  },
}
