<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          mdi-account-plus
        </v-icon>
        Add Person
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Add Person to Flight
      </v-card-title>

      <v-card-text>
        <user-person-select @personSelected="addPersonToFlight"></user-person-select>
      </v-card-text>
      <v-card-text>
        <v-btn
          color="primary"
          @click="addPersonToFlight({crew: true, firstname: '', lastname: '', birth: '', nationality: '', document_id: '', document_validity: ''})"
        >
          <v-icon left>
            mdi-account-plus
          </v-icon>
          Add Blank Person
        </v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <base-cancel-button
          @click="dialog = false"
        >
          Close
        </base-cancel-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserPersonSelect from '@/components/User/UserPersonSelect'
import { mapMutations } from 'vuex'

export default {
  name: 'PersonsOnBoardSelector',
  components: { UserPersonSelect },
  data() {
    return {
      dialog: null,
    }
  },
  methods: {
    addPersonToFlight(person) {
      this.ADD_PERSON_TO_FLIGHT(person)
      this.dialog = false
    },
    ...mapMutations({ ADD_PERSON_TO_FLIGHT: 'flight/ADD_PERSON_TO_FLIGHT' }),
  },
}
</script>

<style scoped>

</style>
