import Vue from 'vue'
import Dialog from './dialog'

const Plugin = {
  install(Vue, options) {
    Vue.prototype.$dialog = Dialog
  },
}

Vue.use(Plugin)
