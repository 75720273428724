import Vue from 'vue'
import BaseText from '@/components/base/Text'
import Heading from '@/components/base/Heading'
import Subheading from '@/components/base/Subheading'
import BaseCancelButton from '@/components/base/BaseCancelButton'
import BaseSaveButton from '@/components/base/BaseSaveButton'
import BaseDeleteButton from '@/components/base/BaseDeleteButton'
import ShowSingleFlightSheet from '@/components/Flight/ShowSingleFlightSheet'

Vue.component('ShowSingleFlightSheet', ShowSingleFlightSheet)
Vue.component('BaseCancelButton', BaseCancelButton)
Vue.component('BaseSaveButton', BaseSaveButton)
Vue.component('BaseDeleteButton', BaseDeleteButton)

Vue.component('BaseText', BaseText)
Vue.component('BaseHeading', Heading)
Vue.component('BaseSubHeading', Subheading)
