<template>
  <v-btn
    outlined
    color="error"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>Löschen</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseDeleteButton',
}
</script>

<style scoped>

</style>
