<template>
  <v-app>
    <v-main>
      <router-view></router-view>
      <the-update-button></the-update-button>
      <base-snackbar></base-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import TheUpdateButton from '@/components/TheUpdateButton'
import BaseSnackbar from '@/components/base/BaseSnackbar'

export default {
  components: { BaseSnackbar, TheUpdateButton },
}
</script>

<style>
</style>
